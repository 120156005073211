var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "../../../theme";
import { css } from "@emotion/react";
import { FlexColumn } from "../../../box";
export var Image = styled.img(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100%;\n  width: 100%;\n  max-height: 295px;\n  margin-top: 20px;\n  border-radius: 8px;\n  border: 1px solid ", ";\n  pointer-events: none;\n  opacity: 0.6;\n  filter: saturate(25%);\n"], ["\n  height: 100%;\n  width: 100%;\n  max-height: 295px;\n  margin-top: 20px;\n  border-radius: 8px;\n  border: 1px solid ", ";\n  pointer-events: none;\n  opacity: 0.6;\n  filter: saturate(25%);\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.primary;
});
export var Label = styled.label(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 1.25em;\n  line-height: 1.75em;\n  opacity: 0.6;\n  cursor: not-allowed;\n  filter: saturate(25%);\n"], ["\n  font-size: 1.25em;\n  line-height: 1.75em;\n  opacity: 0.6;\n  cursor: not-allowed;\n  filter: saturate(25%);\n"])));
export var SignatureContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  max-width: 580px;\n  margin: 0 auto;\n  margin-bottom: 15px;\n\n  ", "\n"], ["\n  width: 100%;\n  max-width: 580px;\n  margin: 0 auto;\n  margin-bottom: 15px;\n\n  ", "\n"])), function (_a) {
    var disabled = _a.disabled;
    return disabled && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      cursor: not-allowed;\n    "], ["\n      cursor: not-allowed;\n    "])));
});
export var SignatureCanvasContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  div {\n    color: ", ";\n    path {\n      stroke: ", ";\n    }\n    path:first-child {\n      fill: ", ";\n    }\n  }\n  svg {\n    fill: ", ";\n  }\n  background-color: ", "10;\n\n  cursor: pointer;\n  position: relative;\n  border-radius: 8px;\n  border: 1px solid ", ";\n  transition: all 0.5s ease-in;\n  ", "\n  ", "\n"], ["\n  div {\n    color: ", ";\n    path {\n      stroke: ", ";\n    }\n    path:first-child {\n      fill: ", ";\n    }\n  }\n  svg {\n    fill: ", ";\n  }\n  background-color: ", "10;\n\n  cursor: pointer;\n  position: relative;\n  border-radius: 8px;\n  border: 1px solid ", ";\n  transition: all 0.5s ease-in;\n  ", "\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.primary;
}, function (_a) {
    var filled = _a.filled;
    return filled
        ? "background-color: #fff;"
        : "background-color:  rgba(43, 183, 217, 0.05);";
}, function (_a) {
    var disabled = _a.disabled;
    return disabled &&
        "\n      pointer-events: none;\n      opacity: 0.6;\n      filter: saturate(25%);\n  ";
});
export var Header = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: 1fr 4.335rem;\n\n  margin-bottom: 20px;\n  min-height: 30px; // Added space for ClearButton to not move when hidden\n\n  ", " {\n    flex-wrap: nowrap;\n    flex-direction: row;\n  }\n"], ["\n  display: grid;\n  grid-template-columns: 1fr 4.335rem;\n\n  margin-bottom: 20px;\n  min-height: 30px; // Added space for ClearButton to not move when hidden\n\n  ", " {\n    flex-wrap: nowrap;\n    flex-direction: row;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.media.md;
});
export var ButtonLayoutWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  align-self: flex-end;\n  ", " {\n    align-self: inherit;\n  }\n"], ["\n  display: flex;\n  align-self: flex-end;\n  ", " {\n    align-self: inherit;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.media.md;
});
export var DisplayWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: none;\n  ", "\n"], ["\n  display: none;\n  ", "\n"])), function (_a) {
    var isVisible = _a.isVisible;
    return isVisible && css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n      display: block;\n    "], ["\n      display: block;\n    "])));
});
export var PlaceholderMessage = styled(FlexColumn)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  justify-content: center;\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  text-align: center;\n  line-height: 1.75em;\n  font-size: 1.25em;\n  color: ", ";\n  pointer-events: none;\n  user-select: none;\n"], ["\n  justify-content: center;\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  text-align: center;\n  line-height: 1.75em;\n  font-size: 1.25em;\n  color: ", ";\n  pointer-events: none;\n  user-select: none;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.primary;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
