// Admin logout event identifiers
export const SHOULD_START_TIMEOUT_LOGOUT_ADMIN =
  "SHOULD_START_TIMEOUT_LOGOUT_ADMIN";
export const STARTED_TIMEOUT_LOGOUT_ADMIN = "STARTED_TIMEOUT_LOGOUT_ADMIN";
export const SHOULD_LOGOUT_ADMIN = "SHOULD_LOGOUT_ADMIN";

// Resident Experience (RE) logout event identifiers
export const SHOULD_START_TIMEOUT_LOGOUT_RE = "SHOULD_START_TIMEOUT_LOGOUT_RE";
export const STARTED_TIMEOUT_LOGOUT_RE = "STARTED_TIMEOUT_LOGOUT_RE";
export const SHOULD_LOGOUT_RE = "SHOULD_LOGOUT_RE";
export const LOGGED_OUT_DUE_TO_INACTIVITY = "LOGGED_OUT_DUE_TO_INACTIVITY";

/**
 * List of DOM events that indicate user activity.
 * Any of these events will reset the inactivity timer.
 */
export const EVENTS = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
  "touchstart",
  "mousewheel",
  "touchmove",
  "touchend",
  "touchcancel",
  "keydown",
];

/**
 * Auto logout timeout durations (in milliseconds)
 * - RESIDENT_EXPERIENCE_OR_ADMIN_PAGE: 1.5 minutes of inactivity on specific pages
 * - INACTIVITY_ACCROSS_APPLICATION: 15 minutes of inactivity across the entire app
 */
export const AUTO_LOGOUT_TIMES = {
  RESIDENT_EXPERIENCE_OR_ADMIN_PAGE: 1.5 * 60 * 1000, // 1.5 minutes
  INACTIVITY_ACCROSS_APPLICATION: 15 * 60 * 1000, // 15 minutes
} as const;
