var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import MarkdownToJsx from "markdown-to-jsx";
import { stripHtml } from "string-strip-html";
import { styled } from "../theme";
import { IconRenderer } from "./renderers/IconRenderer";
import { LinkRenderer } from "./renderers/LinkRenderer";
import { DateRenderer } from "./renderers/DateRenderer";
import { StateNameRenderer } from "./renderers/StateNameRenderer";
import { ModalRenderer } from "./renderers/ModalRenderer";
import { parse } from "./template";
var TEMPLATE_PARSER_OPTS = {
    regex: /\[{2}(.+?)\]{2}/g,
    skipUndefined: true,
    throwOnUndefined: false,
};
export var interpolate = function (values) {
    if (values === void 0) { values = {}; }
    return function (source, defaultValue) {
        if (defaultValue === void 0) { defaultValue = ""; }
        return parse(source, values, TEMPLATE_PARSER_OPTS, defaultValue);
    };
};
export var Markdown = function (_a) {
    var staticContent = _a.staticContent, _b = _a.values, values = _b === void 0 ? {} : _b, source = _a.source, transformUrl = _a.transformUrl;
    var overrides = {
        pre: StyledPre,
        code: StyledCode,
        a: function (props) { return React.createElement("span", null, props.children); },
        "res:icon": function (props) { return React.createElement(IconRenderer, __assign({}, props)); },
        "res:date": function (props) { return React.createElement(DateRenderer, __assign({}, props, { values: values })); },
        "res:link": function (props) { return (React.createElement(LinkRenderer, __assign({}, props, { values: values, transformUrl: transformUrl }))); },
        "res:state": function (props) { return (React.createElement(StateNameRenderer, __assign({}, props, { values: values }))); },
        "res:modal": function (props) { return (React.createElement(ModalRenderer, __assign({}, props, { staticContent: staticContent }))); },
        del: function (props) { return React.createElement("u", null, props.children); },
    };
    return (React.createElement(MarkdownToJsx, { options: { overrides: overrides } }, stripHtml(interpolate(values)(source), {
        ignoreTags: ["res", "a", "title", "TITLE", "Title", "small"],
    }).result));
};
var StyledPre = styled.pre(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-radius: 6px;\n  padding: 8px;\n  background: ", ";\n  border: 1px solid ", ";\n\n  code {\n    border: initial;\n    padding: initial;\n    border-radius: initial;\n    background: transparent;\n  }\n"], ["\n  border-radius: 6px;\n  padding: 8px;\n  background: ", ";\n  border: 1px solid ", ";\n\n  code {\n    border: initial;\n    padding: initial;\n    border-radius: initial;\n    background: transparent;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.darkBlue5;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.darkBlue10;
});
var StyledCode = styled.code(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-radius: 6px;\n  padding: 8px;\n  background: ", ";\n  border: 1px solid ", ";\n"], ["\n  border-radius: 6px;\n  padding: 8px;\n  background: ", ";\n  border: 1px solid ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.darkBlue5;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.darkBlue10;
});
var templateObject_1, templateObject_2;
