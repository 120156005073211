import React from "react";
import {DataNode} from "rc-tree/lib/interface";

import {FacilityDropdownTreeSelectRenderer} from "../../../../atoms/facility-dropdown-tree-select";
import {prepareFacilitiesTreeForDisplay} from "../../../../atoms/facility-dropdown-tree-select/helpers";
import {createFacilitiesTreeWithPccIcons} from "../../utils";
import {FacilitySettingsDropdownWrapper} from "./styles";

export type Props = Readonly<{
  facilitiesTree: DataNode[];
  activeId: string;
  onChange: (id: string) => void;
}>;

export const SingleFacilityDropdownSelect = ({
  activeId,
  facilitiesTree,
  onChange,
}: Props) => {
  const {hasSingleOrganization, hasSingleFacility} =
    prepareFacilitiesTreeForDisplay(facilitiesTree);

  return (
    facilitiesTree?.length > 0 && (
      <FacilitySettingsDropdownWrapper>
        <FacilityDropdownTreeSelectRenderer
          activeFacilitiesTreeKeys={[activeId]}
          facilitiesTree={createFacilitiesTreeWithPccIcons(facilitiesTree)}
          hasOnlyOne
          hideTrigger={hasSingleFacility}
          triggerLabel="Facility"
          searchPlaceholder={
            hasSingleOrganization
              ? "Search by facility"
              : "Search by organization or facility"
          }
          setActiveFacilities={facilities =>
            onChange(facilities.checkedLeafKeys[0])
          }
          autoComplete="none"
        />
      </FacilitySettingsDropdownWrapper>
    )
  );
};
