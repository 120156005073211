import React, {useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useFormikContext} from "formik";
import {Tag, Text} from "@reside/ui";
import {isEmpty} from "lodash";

import {
  CreateThemeRequest,
  CustomThemeModel,
} from "../../../../services/BrandingService";
import {OneThemeWrapper, ThemeContent, ThemeName} from "./styles";
import {PalettePreview} from "../Colors/PalettePreview";
import {IconButton} from "../../../../atoms/icon-button";
import {DeleteThemeModal} from "../DeleteThemeModal";
import {useActivateBrandingTheme} from "../../hooks/useActivateBrandingTheme";
import {useActivateDefaultTheme} from "../../hooks/useActivateDefaultTheme";
import {DEFAULT_THEME} from "../../constants";

export const OneCustomTheme = ({
  theme,
  themeNumber,
}: {
  theme: CustomThemeModel;
  themeNumber?: number;
}) => {
  const history = useHistory();
  const {organizationId} = useParams<{organizationId: string}>();
  const {values} = useFormikContext<CreateThemeRequest>();

  const {mutate: activateTheme} = useActivateBrandingTheme();
  const {mutate: activateDefaultTheme} = useActivateDefaultTheme();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const isDefaultTheme = theme.id === DEFAULT_THEME.id;

  return (
    <OneThemeWrapper key={theme.id}>
      <div
        style={{
          display: "flex",
          visibility: isDefaultTheme ? "hidden" : "visible",
          alignItems: "center",
          marginBottom: "16px",
          marginRight: "-8px",
          justifyContent: "flex-end",
        }}
      >
        <IconButton
          color="gray100"
          frameless
          iconName="pencil"
          onClick={() =>
            history.push(
              `/admin/organizations/${organizationId}/branding/${theme.id}`,
            )
          }
        />
        <IconButton
          color="gray100"
          frameless
          iconName="trash"
          onClick={() => {
            setOpenDeleteModal(true);
          }}
        />
        <DeleteThemeModal
          themeName={values.theme_name}
          themeId={theme.id}
          isOpen={openDeleteModal}
          onRequestClose={() => setOpenDeleteModal(false)}
        />
      </div>

      <ThemeContent
        active={theme.active}
        onClick={() => {
          if (isDefaultTheme) {
            activateDefaultTheme();
          } else {
            activateTheme({themeId: theme.id});
          }
        }}
      >
        <ThemeName>
          <Text
            as="p"
            style={{
              fontWeight: 600,
              wordBreak: "break-word",
              fontSize: "16px",
              textAlign: theme.active ? "left" : "center",
            }}
          >
            {theme.theme_name}
          </Text>
          {theme.active && (
            <Tag style={{minWidth: "60px"}} color="green100">
              Active
            </Tag>
          )}
        </ThemeName>

        <PalettePreview
          colors={isEmpty(theme.colors) ? DEFAULT_THEME.colors : theme.colors}
        />
      </ThemeContent>
    </OneThemeWrapper>
  );
};
