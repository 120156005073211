import React from "react";
import {Button} from "@reside/ui";
import {isEmpty} from "lodash";
import {useFormikContext} from "formik";
import {useHistory, useParams} from "react-router-dom";

import {ButtonsWrapper} from "../styles";
import {useCreateBrandingTheme} from "../hooks/useCreateBrandingTheme";
import {CreateThemeRequest} from "../../../services/BrandingService";
import {useUpdateBrandingTheme} from "../hooks/useUpdateBrandingTheme";
import {BRANDING_COLORS} from "../constants";

interface BrandingActionsProps {
  dirty: boolean;
}

export const BrandingActions = ({dirty}: BrandingActionsProps): JSX.Element => {
  const history = useHistory();
  const {themeId} = useParams<{themeId: string}>();
  const {values, validateForm, setTouched, setSubmitting} =
    useFormikContext<CreateThemeRequest>();

  const {mutate: createTheme, isLoading: isCreating} = useCreateBrandingTheme();
  const {mutate: updateTheme, isLoading: isUpdating} = useUpdateBrandingTheme();

  const isEditing = Boolean(themeId);

  const handleSave = () => {
    setSubmitting(true);
    if (isEditing) {
      updateTheme({...values, colors: BRANDING_COLORS[+values.colors]});
    } else {
      createTheme({...values, colors: BRANDING_COLORS[+values.colors]});
    }
  };

  return (
    <ButtonsWrapper>
      <Button color="primary" outline onClick={() => history.goBack()}>
        Cancel
      </Button>
      <Button
        type="submit"
        color="primary"
        disabled={!dirty || isCreating || isUpdating}
        onClick={async () => {
          setTouched({theme_name: true, logo: true} as any);
          const errors = await validateForm();

          if (isEmpty(errors)) {
            handleSave();
          }
        }}
      >
        {isCreating || isUpdating ? "Saving..." : "Save changes"}
      </Button>
    </ButtonsWrapper>
  );
};
