import React from "react";
import {H3, TextField, styled} from "@reside/ui";

const StyledTextField = styled(TextField)`
  input::placeholder {
    color: ${({theme}) => theme.color.darkBlue90};
    opacity: 0.35;
  }
`;

export const ThemeName = (): JSX.Element => {
  return (
    <div style={{paddingRight: "32px"}} id="theme-name">
      <H3>Theme name</H3>
      <div style={{paddingLeft: "10px"}}>
        <StyledTextField
          style={{marginRight: "16px"}}
          name="theme_name"
          label=""
          placeholder="Eg. Valentine's Day"
          required
          max={16}
        />
      </div>
    </div>
  );
};
