import React from "react";
import {Text} from "@reside/ui";

export const Specifications = ({specs}: {specs: string[]}): JSX.Element => {
  return (
    <div style={{marginTop: "16px"}}>
      {specs.map(spec => (
        <Text
          style={{marginBottom: "8px"}}
          as="p"
          key={spec}
          dangerouslySetInnerHTML={{__html: spec}}
        />
      ))}
    </div>
  );
};
