import React from "react";
import {connect} from "react-redux";
import {AppState, select} from "../../store";
import {AdminNavigationHeader} from "../../atoms/admin-navigation-header";
import {AdminActionRow} from "../../atoms/admin-action-row";
import {DocumentTitle} from "../../atoms/document-title";
import {DashboardGrid, DashboardGridHeader} from "../../atoms/dashboard-table";
// TODO: move the dashboard-table atoms inside the page/atoms folder
import {DashboardApproveModal} from "../../atoms/dashboard-table/DashboardApproveModal";
import {DashboardArchiveModal} from "../../atoms/dashboard-table/DashboardArchiveModal";
import {DashboardDeleteAdmissionModal} from "../../atoms/dashboard-table/DashboardDeleteAdmissionModal";
import {DashboardReturnToResidentModal} from "../../atoms/dashboard-table/DashboardReturnToResidentModal";
import {
  ResidentLoginModalProvider,
  ResidentLoginModal,
} from "../../atoms/dashboard-table/atoms/resident-login-modal";
import {DashboardModalContextProvider} from "../../atoms/dashboard-table/DashboardModalContext";
import {PccDashboardCheckboxesContextProvider} from "../../atoms/dashboard-table/atoms/pcc-document-upload-modal";
import {FacilityDropdownTreeSelect} from "../../atoms/facility-dropdown-tree-select";
import {Copyright} from "../../atoms/copyright";
import {
  ChangeHistoryModal,
  ChangeHistoryModalProvider,
} from "../../atoms/dashboard-table/atoms/change-history-modal";
import {AdminAdmissionsSearch} from "../../atoms/admin-search";
import {
  DashboardLayout,
  FullWidthDashboardHeader,
  FullWidthDashboardLayoutContent,
} from "./styles";
import {useFetchActiveBrandingTheme} from "../page-admin-organizations-branding/hooks/useFetchActiveBrandingTheme";
import {BackdropSpinner} from "../../atoms/spinner";

type StateProps = {
  isResideAdmin: boolean;
  organizationIdByUser: string;
};

const PageAdminDashboardRenderer = ({
  isResideAdmin,
  organizationIdByUser,
}: StateProps) => {
  // Fetch active custom branding theme
  const {isLoading} = useFetchActiveBrandingTheme({
    organizationIdByUser,
  });

  return (
    <BackdropSpinner active={isLoading}>
      <DashboardLayout>
        <DocumentTitle title="Reside - Admin - Dashboard" />
        <AdminNavigationHeader />

        {/*Note: The Modal context must contain PCC modal context, so the inner*/}
        {/*context * can update the parent context.*/}
        <DashboardModalContextProvider>
          <PccDashboardCheckboxesContextProvider
            isPccDashboardCheckboxesFeatureEnabled={!isResideAdmin}
          >
            <ResidentLoginModalProvider>
              <ChangeHistoryModalProvider>
                <FullWidthDashboardHeader>
                  <AdminActionRow
                    facilitySelector={<FacilityDropdownTreeSelect />}
                    search={<AdminAdmissionsSearch />}
                  />
                  <DashboardGridHeader />
                </FullWidthDashboardHeader>
                <FullWidthDashboardLayoutContent>
                  <DashboardGrid />
                </FullWidthDashboardLayoutContent>
                <DashboardApproveModal />
                <DashboardArchiveModal />
                <DashboardDeleteAdmissionModal />
                <DashboardReturnToResidentModal />
                <ResidentLoginModal />
                <ChangeHistoryModal />
              </ChangeHistoryModalProvider>
            </ResidentLoginModalProvider>
          </PccDashboardCheckboxesContextProvider>
        </DashboardModalContextProvider>
        <Copyright />
      </DashboardLayout>
    </BackdropSpinner>
  );
};

const mapStateToProps = (
  state: AppState & {adminSession: {user: {organization: {id: string}}}},
): StateProps => ({
  isResideAdmin: select.adminSession.isResideAdmin(state),
  organizationIdByUser: state?.adminSession?.user?.organization?.id,
});

export const PageAdminDashboard = connect(mapStateToProps)(
  PageAdminDashboardRenderer,
);
