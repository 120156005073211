import React, {FormEvent} from "react";
import {FastFieldProps, Field, FieldProps, useField} from "formik";
import {ImagePreviewContainer, ImageUploadButton} from "./styles";
import {FileValue, FileInput} from "@reside/ui";
import {ImageUploadProps} from "./types";
import {useObjectUrl} from "../../pages/page-admin-organizations-branding/hooks/useObjectUrl";

export const ImageUpload = (props: ImageUploadProps): JSX.Element => {
  const {
    name,
    uploadButtonText = "Choose image",
    defaultSrc,
    fit = "fill",
    acceptedMediaTypes = ["image/*"],
  } = props;

  const [field] = useField(name);
  const previewSrc = useObjectUrl(field.value, defaultSrc);

  return (
    <Field name={name}>
      {({field, form}: FieldProps<FileValue> | FastFieldProps<FileValue>) => {
        const {setFieldTouched, setFieldValue} = form;

        const onChange = (event: FormEvent<HTMLInputElement>) => {
          const file = event.currentTarget.files[0];
          if (file) {
            setFieldTouched(name, true);
            setFieldValue(name, file);
          }
        };

        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
            }}
          >
            {(field.value || defaultSrc) && (
              <ImagePreviewContainer fit={fit}>
                <img src={previewSrc || defaultSrc} alt={`${name}-preview`} />
              </ImagePreviewContainer>
            )}
            <ImageUploadButton>
              <span>{uploadButtonText}</span>
              <FileInput
                name={name}
                acceptedMediaTypes={acceptedMediaTypes}
                onChange={onChange}
              />
            </ImageUploadButton>
          </div>
        );
      }}
    </Field>
  );
};
