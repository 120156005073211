import {useQuery} from "react-query";

import {
  FeatureFlagsService
} from "../../../services/FeatureFlagsService";

export const useFetchFeatureFlagsByOrganizations = () =>
  useQuery("useFetchFeatureFlagsByOrganizations", async () => {
    const {data} = await FeatureFlagsService.get_organization_feature_flags();
    return data;
  });
