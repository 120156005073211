import {css} from "@emotion/css";
import {theme} from "@reside/ui";

export const TreeSelectStyledClasses = {
  treeSelect: css`
    display: flex;
    flex-direction: column;
    height: 100%;

    &.single-select-mode {
      .rc-tree-treenode-switcher-open:only-child {
        .rc-tree-node-content-wrapper.rc-tree-node-content-wrapper-open {
          display: none;
        }
      }

      .rc-tree-node-content-wrapper.rc-tree-node-content-wrapper-open {
        text-transform: uppercase;
        margin-left: 24px;
        color: ${theme.color.gray100};
      }

      .rc-tree-child-tree {
        .rc-tree-treenode-switcher-open:hover {
          cursor: pointer;
          color: ${theme.color.darkBlue70};
        }
      }

      .rc-tree-node-selected {
        color: ${theme.color.primary};
      }
    }
  `,
  macroActions: css`
    color: ${theme.color.darkBlue10};
    margin: 8px 8px 4px 8px;

    span {
      color: ${theme.color.primary};
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  `,
  emptySearch: css`
    margin: 32px 24px;
    text-align: center;
  `,
};
