import {CreateStaffUserDto} from "@reside/reside-api-app";
import {userTypes} from "../../../utils/constants";
import {OrganizationModel} from "../../../utils/types/organization";

export const getTypeForRole = (roleId: string) => {
  switch (roleId) {
    case userTypes.FACILITY_ADMIN:
    case userTypes.ADMISSIONS_DIRECTOR:
    case userTypes.REGIONAL_ADMIN:
    case userTypes.ORGANIZATION_ADMIN:
    case userTypes.RESIDE_ADMIN:
      return CreateStaffUserDto.TypeEnum.ADMINISTRATOR;
    case userTypes.CLERK:
      return CreateStaffUserDto.TypeEnum.CLERK;
    default:
      return null;
  }
};

/**
 * Checks if the user has no facility;
 * @param roleId - the role id of the user
 * @returns - true if the user has no facility, false otherwise;
 */

export const normalizeValues = (values: CreateStaffUserDto) => {
  const data = {
    ...values,
    email: values.email.toLowerCase(),
    type: getTypeForRole(values.roleId),
  };

  if (values.roleId === userTypes.RESIDE_ADMIN) {
    delete data.facilities;
    delete data.organizationId;
  } else if (values.roleId === userTypes.ORGANIZATION_ADMIN) {
    delete data.facilities;
  }

  return data;
};

export const getValidationRules = (
  values: CreateStaffUserDto,
  organizations: readonly OrganizationModel[],
) => {
  const org = organizations.find(o => o.id === values.organizationId);
  return {
    firstName: "required",
    lastName: "required",
    roleId: "required",
    email: ["required", "email", `whitelist-domain-name:${org?.domains ?? ""}`],
    phone: ["required", "phone"],
    organizationId: values.roleId !== userTypes.RESIDE_ADMIN && "required",
    facilities: values.roleId !== userTypes.RESIDE_ADMIN && values.roleId !== userTypes.ORGANIZATION_ADMIN && [
      "required",
      "facility-name",
    ],
  };
};
