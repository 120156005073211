var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useMemo } from "react";
import { 
/**
 * It's important to not mutate the original date prop.
 */
toDate as cloneDate, addDays, addMonths, addYears, format, isAfter, isBefore, isSameMonth, setMonth, setYear, } from "date-fns";
import { range } from "lodash";
import { DATE_FORMAT } from "../date-picker/date";
import { styled } from "../theme";
import { Select } from "../form/atoms/select";
import { FlexRow } from "../box";
export var YearMonthPicker = function (_a) {
    var date = _a.date, range = _a.range, tabIndex = _a.tabIndex, onChange = _a.onChange;
    var start = useMemo(function () { return subRange(new Date(), range.before); }, [range.before]);
    var end = useMemo(function () { return addRange(new Date(), range.after); }, [range.after]);
    var handleMonthChange = function (event) {
        return onChange(guardDateRange(setMonth(cloneDate(date), parseInt(event.target.value, 10))), "month");
    };
    var handleYearChange = function (event) {
        return onChange(guardDateRange(setYear(cloneDate(date), parseInt(event.target.value, 10))), "year");
    };
    var guardDateRange = function (date) {
        return isReallyBefore(date, start) ? start : isReallyAfter(date, end) ? end : date;
    };
    return (React.createElement(FlexRow, null,
        React.createElement(InlineSelect, { tabIndex: tabIndex, name: "month", onChange: handleMonthChange, value: date === null || date === void 0 ? void 0 : date.getMonth(), "data-test-id": "yearMonthPicker-monthSelect" }, renderMonthOptions(start, end, cloneDate(date))),
        React.createElement(Year, null,
            React.createElement(InlineSelect, { tabIndex: tabIndex, name: "year", onChange: handleYearChange, value: date === null || date === void 0 ? void 0 : date.getFullYear(), "data-test-id": "yearMonthPicker-yearSelect" }, renderYearOptions(start.getFullYear(), end.getFullYear() + 1)))));
};
YearMonthPicker.defaultProps = {
    tabIndex: 0,
};
var Year = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 80%;\n"], ["\n  width: 80%;\n"])));
var InlineSelect = styled(Select)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 1.2em;\n  padding: 6px;\n  color: inherit;\n  border: 1px solid transparent;\n\n  &:hover {\n    cursor: pointer;\n  }\n\n  &:focus {\n    border-color: ", ";\n  }\n"], ["\n  font-size: 1.2em;\n  padding: 6px;\n  color: inherit;\n  border: 1px solid transparent;\n\n  &:hover {\n    cursor: pointer;\n  }\n\n  &:focus {\n    border-color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.fillInfo;
});
var addRange = function (date, _a) {
    var amount = _a[0], unit = _a[1];
    var fn = {
        year: addYears,
        month: addMonths,
        day: addDays,
    }[unit];
    return fn(date, amount);
};
var subRange = function (date, _a) {
    var amount = _a[0], unit = _a[1];
    return addRange(date, [-amount, unit]);
};
var isReallyBefore = function (date, otherDate) {
    return isBefore(date, otherDate) && isSameMonth(date, otherDate) === false;
};
var isReallyAfter = function (date, otherDate) {
    return isAfter(date, otherDate) && isSameMonth(date, otherDate) === false;
};
var renderYearOptions = function (startYear, endYear) {
    return range(startYear, endYear).map(function (year) { return (React.createElement("option", { key: year, value: year }, year)); });
};
var renderMonthOptions = function (start, end, currentDate) {
    if (currentDate === void 0) { currentDate = new Date(); }
    return range(12).map(function (month) {
        var then = setMonth(currentDate, month);
        return (React.createElement("option", { key: month, value: month, disabled: isReallyBefore(then, start) || isReallyAfter(then, end) }, format(then, DATE_FORMAT.MONTH_NAME)));
    });
};
var templateObject_1, templateObject_2;
