import React from "react";
import {cx} from "@emotion/css";
import {useTimeout} from "react-use";
import {styled, Text, theme} from "@reside/ui";

import {ReactComponent as ScrollDownImage} from "./assets/arrow-scrolldown.svg";
import {hexToRgba} from "../../pages/page-admin-organizations-branding/utils";

type Props = Readonly<{
  hidden: boolean;
  hideTimeout?: number;
}>;

export const ScrollReminder = ({hidden, hideTimeout}: Props) => {
  const [isHidden] = useTimeout(hideTimeout);

  return (
    <Container
      className={cx({
        hidden: hidden || isHidden(),
      })}
    >
      <ScrollDownImage style={{marginBottom: "20px"}} />

      <Text fontSize="1.25em" color="fillSuccess">
        Scroll for more
      </Text>
    </Container>
  );
};

ScrollReminder.defaultProps = {
  hidden: false,
  hideTimeout: 5300,
};

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  z-index: 3;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background-color: ${hexToRgba(theme.color.fillSuccess, 0.1)};
  bottom: 150px;
  animation: bounce 4s 0.25s 4 alternate both;
  pointer-events: none;

  img {
    margin-bottom: 20px;
  }

  &.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 300ms;
  }
`;
