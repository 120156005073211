import {useMutation, useQueryClient} from "react-query";
import {BrandingService} from "../../../services/BrandingService";
import {useParams} from "react-router";
import {useHistory} from "react-router-dom";
import Alert from "react-s-alert";

export const useDeleteBrandingTheme = () => {
  const {organizationId} = useParams<{organizationId: string}>();
  const queryClient = useQueryClient();
  const history = useHistory();

  return useMutation(
    async (themeId: string) => {
      const response = await BrandingService.deleteTheme(
        organizationId,
        themeId,
      );
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["themes"],
        });
        history.push(`/admin/organizations/${organizationId}/edit`);
        Alert.success("Theme deleted successfully");
      },
      onError: () => {
        Alert.error("Failed to delete theme");
      },
    },
  );
};
