import {styled} from "@reside/ui";

export const LogoImage = styled.img`
  max-width: 200px;
  max-height: 130px;
  width: auto;
  height: auto;
  object-fit: contain;
  object-position: center;
`;

export const CustomLogo = ({
  src,
  alt,
  style,
}: {
  src: string;
  alt: string;
  style?: React.CSSProperties;
}): JSX.Element => {
  return <LogoImage src={src} alt={alt} style={style} />;
};
