import {DataNode} from "rc-tree/lib/interface";

export const prepareFacilitiesTreeForDisplay = (
  facilitiesTree: ReadonlyArray<DataNode>,
) => {
  // If facilitiesTree is not an array, provide controls for SingleFacilityDropdownSelect component
  if (!Array.isArray(facilitiesTree)) {
    return {
      hasSingleOrganization: true,
      hasSingleFacility: true,
      modifiedFacilityTree: [],
    };
  }

  const hasSingleOrganization = facilitiesTree.length === 1;

  const hasSingleFacility =
    facilitiesTree.reduce((acc, cur) => acc + cur?.children?.length ?? 0, 0) ===
    1;

  // Append className and checkable state to each facility node
  const modifiedFacilityTree = facilitiesTree.map(parent => ({
    ...parent,
    className: `facility-parent ${
      hasSingleOrganization ? "hidden-parent" : ""
    }`,
    checkable: !hasSingleOrganization, // Prevent cheking organization if there is only one organization
  }));

  return {hasSingleOrganization, hasSingleFacility, modifiedFacilityTree};
};
