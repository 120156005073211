import React from "react";
import {Text, Icon} from "@reside/ui";
import {ButtonLink} from "../../../../atoms/button-link/ButtonLink";
import {useFetchAllBrandingThemes} from "../../hooks/useFetchAllBrandingThemes";
import {OneCustomTheme} from "./OneCustomTheme";
import {Spinner} from "../../../../atoms/spinner";
import {MAX_THEMES} from "../../constants";

const baseUrl = "/admin/organizations";

interface ThemesGaleryProps {
  organizationId: string;
}

export const ThemesGalery = ({organizationId}: ThemesGaleryProps) => {
  const {data: themes, isFetching} = useFetchAllBrandingThemes();

  if (isFetching) {
    return <Spinner />;
  }

  return (
    <div>
      <Text as="p" fontSize={16}>
        Choose a theme:
      </Text>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(240px, 1fr))",
          gridGap: "8px",
          rowGap: "40px",
          justifyContent: "center",
        }}
      >
        {themes?.content.map((theme, index) => (
          <OneCustomTheme
            key={theme.id}
            theme={theme}
            themeNumber={index + 1}
          />
        ))}
      </div>

      <Text as="p" fontSize={16} style={{marginTop: "32px"}}>
        {themes?.content.length === MAX_THEMES ? (
          <>
            <Icon name="info" style={{marginRight: "8px", fontSize: "16px"}} />
            You&apos;ve reached the limit of 4 themes. Delete an existing theme
            to add a new one.
          </>
        ) : (
          ""
        )}
      </Text>
      <ButtonLink
        style={{marginLeft: "auto", marginTop: "16px"}}
        to={`${baseUrl}/${organizationId}/branding`}
        color="primary"
        disabled={themes?.content.length === MAX_THEMES}
      >
        <Icon name="add" style={{marginRight: "8px", fontSize: "16px"}} />
        New Theme
      </ButtonLink>
    </div>
  );
};
