var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { css, cx } from "@emotion/css";
import { camelCase } from "lodash";
import { Text } from "../../text";
import { Box } from "../../box";
import { Icon } from "../../icon";
import { FlagIcon } from "../../flag-icon";
import { useBlockComponentContext } from "../../form-blocks";
export var SlideRow = function (_a) {
    var index = _a.index, translationKey = _a.translationKey, active = _a.active, hasFlags = _a.hasFlags, onClick = _a.onClick;
    var _b = useBlockComponentContext(), Translated = _b.Translated, translate = _b.translate;
    return (React.createElement("div", { onClick: function () { return onClick(index); }, className: s.container },
        React.createElement(Box, { flexDirection: "row", alignItems: "center", justifyContent: "space-between", className: "sliderow-0-47" },
            React.createElement(Box, { flexDirection: "row", className: cx(s.titleContainer, { active: active }) },
                active && (React.createElement("div", { className: "sliderow-0-46" },
                    React.createElement(Icon, { name: "sort-down", color: "#007bcd", size: 14 }))),
                React.createElement(Text, { className: s.title, fontSize: "1.25em", "data-test-id": "sliderow-" + camelCase(translate(translationKey)) },
                    React.createElement(Translated, { translationKey: translationKey }))),
            hasFlags && React.createElement(FlagIcon, null))));
};
var s = {
    container: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    cursor: pointer;\n\n    &:hover * {\n      opacity: 0.7;\n    }\n  "], ["\n    cursor: pointer;\n\n    &:hover * {\n      opacity: 0.7;\n    }\n  "]))),
    titleContainer: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    padding-left: 65px;\n    &.active {\n      padding-left: 45px;\n    }\n  "], ["\n    padding-left: 65px;\n    &.active {\n      padding-left: 45px;\n    }\n  "]))),
    title: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    line-height: 1.5em;\n  "], ["\n    line-height: 1.5em;\n  "]))),
};
var templateObject_1, templateObject_2, templateObject_3;
