import {ApiResponse} from "apisauce";
import {http as featureFlagsHttp} from "./api/restApiClient";

export const FeatureFlagsService = {
  get_facility_feature_flags_types: (): Promise<ApiResponse<any>> =>
    featureFlagsHttp.get("/v3/feature-flags/facilities/feature-types"),

  get_facility_feature_flags: (data: any): Promise<ApiResponse<any>> =>
    featureFlagsHttp.post("/v3/feature-flags/facilities", data),

  update_facility_feature_flags: (
    data: any,
    facility_id: any,
  ): Promise<ApiResponse<any>> =>
    featureFlagsHttp.put(`/v3/feature-flags/facilities/${facility_id}`, data),

  get_organization_feature_flags_types: (): Promise<ApiResponse<any>> =>
    featureFlagsHttp.get("/v3/feature-flags/organizations/feature-types"),

  get_organization_feature_flags: (): Promise<ApiResponse<any>> =>
    featureFlagsHttp.get("/v3/feature-flags/organizations"),
};
