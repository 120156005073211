import {theme} from "@reside/ui";

// Base color palette that all themes extend
const BASE_PALETTE = {
  backgroundDefault: "#FCFCFC",
  backgroundLight: "#FFFFFF",
  fillSuccess: "#117711",
  textSuccess: "#117711",
  textPrimary: "#404040",
};

export const DEFAULT_RESIDE_COLOR_PALETTE = {
  ...BASE_PALETTE,
  primary: theme.color.primary,
  fillInfo: theme.color.fillInfo,
  textInfo: theme.color.textInfo,
};

export const RED = {
  ...BASE_PALETTE,
  primary: "#FF5333",
  fillInfo: "#2288AA",
  textInfo: "#2288AA",
};

export const ORANGE = {
  ...BASE_PALETTE,
  primary: "#FF7711",
  fillInfo: "#2288AA",
  textInfo: "#2288AA",
};

export const YELLOW = {
  ...BASE_PALETTE,
  primary: "#FFEE11",
  fillInfo: "#2288AA",
  textInfo: "#2288AA",
};

export const GREEN = {
  ...BASE_PALETTE,
  primary: "#229911",
  fillInfo: "#2288AA",
  textInfo: "#2288AA",
};

export const MINT = {
  ...BASE_PALETTE,
  primary: "#11EEAA",
  fillInfo: "#2288AA",
  textInfo: "#2288AA",
};

export const BLUE = {
  ...BASE_PALETTE,
  primary: "#1177DD",
  fillInfo: "#1177DD",
  textInfo: "#1177DD",
};

export const PINK = {
  ...BASE_PALETTE,
  primary: "#EE3388",
  fillInfo: "#EE3388",
  textInfo: "#EE3388",
};

export const PURPLE = {
  ...BASE_PALETTE,
  primary: "#6644CC",
  fillInfo: "#6644CC",
  textInfo: "#6644CC",
};
