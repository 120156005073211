import {useQuery} from "react-query";
import {useParams} from "react-router";
import {BrandingService} from "../../../services/BrandingService";
import {DEFAULT_THEME} from "../constants";
export const useFetchSingleTheme = () => {
  const {themeId, organizationId} = useParams<{
    organizationId: string;
    themeId: string;
  }>();

  const isDefaultTheme = themeId === DEFAULT_THEME.id;

  return useQuery(
    ["single_theme", themeId],
    async () => {
      if (isDefaultTheme) {
        return DEFAULT_THEME;
      }

      const response = await BrandingService.getTheme(organizationId, themeId);

      return response.data;
    },
    {enabled: Boolean(themeId)},
  );
};
