import React from "react";
import {FlexRow, getCustomBrandingLogo} from "@reside/ui";
import {connect} from "react-redux";

import {Dispatch} from "../../store";
import {IconButton} from "../../atoms/icon-button";
import {VerifyAnswersForm} from "./atoms/verify-answers-form";
import {CustomLogo} from "../../atoms/custom-logo/CustomLogo";
import {PreviewPageVerifyAnswersProps} from "../page-admin-organizations-branding/atoms/Preview/types";
import {Layout, LayoutContentColumn} from "./styles";
import {DEFAULT_LOGO_URL} from "../page-admin-organizations-branding/constants";
import {FormBoxWrapper} from "./atoms/verify-answers-form/FormBoxWrapper";

type DispatchProps = Readonly<{
  setToken: (token: string) => void;
  onLogout: () => void;
}>;

export const PageAdmissionVerifyAnswersRenderer = ({
  setToken,
  onLogout,
  logoutOnUnauthorized = true,
  logoUrl = DEFAULT_LOGO_URL,
}: DispatchProps & PreviewPageVerifyAnswersProps) => {
  const isResidentExperience = window.location.pathname.includes("/admission/");
  const displayLogoUrl = isResidentExperience
    ? getCustomBrandingLogo(logoUrl)
    : logoUrl;

  return (
    <Layout id="verify-answers">
      <LayoutContentColumn>
        <FlexRow>
          <CustomLogo src={displayLogoUrl} alt="company logo" />
        </FlexRow>
        <FormBoxWrapper>
          <VerifyAnswersForm
            onVerified={setToken}
            onUnauthorized={logoutOnUnauthorized ? onLogout : () => {}}
          />
        </FormBoxWrapper>
        <footer>
          <IconButton background="white" iconName="house" onClick={onLogout}>
            Exit
          </IconButton>
        </footer>
      </LayoutContentColumn>
    </Layout>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  setToken: dispatch.admissionSession.setToken,
  onLogout: dispatch.admissionSession.logout,
});

export const PageAdmissionVerifyAnswers = connect(
  null,
  mapDispatchToProps,
)(PageAdmissionVerifyAnswersRenderer);
