import {styled, DropdownMenuStyled} from "@reside/ui";

export const DropdownMenu = styled(DropdownMenuStyled)`
  margin-top: 8px;
  padding: 12px;
  width: 360px;
  z-index: 1001;
  overflow-y: hidden;
  .rc-tree {
    max-height: 64vh;

    @media (max-height: 740px) {
      max-height: 45vh;
    }
  }
`;

export const Trigger = styled.div`
  &:hover {
    cursor: pointer;
  }
  font-size: 23px;
`;

export const TriggerIconWrapper = styled.div`
  vertical-align: middle;
  padding: 4px;
  display: inline;
  font-size: 12px;
`;
