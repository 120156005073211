import React from "react";
import {H3} from "@reside/ui";
import {CreateThemeRequest} from "../../../../services/BrandingService";
import {useFormikContext} from "formik";
import {CheckableColor, ColorBox} from "./styles";
import {BRANDING_COLORS} from "../../constants";
import {PalettePreview} from "./PalettePreview";

export const Colors = (): JSX.Element => {
  const {values, setFieldValue} = useFormikContext<CreateThemeRequest>();

  return (
    <div id="colors">
      <H3 style={{marginBottom: "16px"}}>Color</H3>
      <div
        style={{
          marginLeft: "2px",
          display: "grid",
          gridTemplateColumns: "repeat(6, 60px)",
          gap: "10px",
        }}
      >
        {BRANDING_COLORS.map((color, index) => (
          <CheckableColor
            checked={index === +values.colors}
            key={color.primary}
          >
            <ColorBox
              style={{cursor: "pointer"}}
              onClick={() => setFieldValue("colors", index)}
              bgColor={color.primary}
              key={color.primary}
            />
          </CheckableColor>
        ))}
      </div>

      <H3 style={{marginBottom: "20px", marginTop: "32px"}}>Palette Preview</H3>

      <PalettePreview align="left" colors={BRANDING_COLORS[+values.colors]} />
    </div>
  );
};
