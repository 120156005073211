import {RoleDto, TableUserDto} from "@reside/reside-api-app";

import {http as userPermissionsHttp} from "../../services/api/restApiClient";

import {
  UserId,
  UserPermissionsResponseItem,
  UsersByPermissionType,
} from "./user-permissions.common";

interface IResolvedVal {
  success: boolean;
  data: UserPermissionsResponseItem[];
}

interface User {
  assignedFacilities?: any;
  fullName: string;
  facilities?: any[];
  facilityName: string;
  id: string;
  clerk: boolean;
  resident: boolean;
  resideAdmin: boolean;
  typeValue: RoleDto.NameEnum;
  role: {name: RoleDto.NameEnum};
}

interface ResponseUsersByPermission {
  category: "OVERVIEW_OVERVIEW" | "ANALYTICS_ADMISSIONS" | "REPORTS_ADMISSION";
  id: string;
  name: TableUserDto.PermissionsEnum;
  status: "ACTIVE";
  type: "REPORTING";
  users: User[];
}

export const resolverGetUserPermissions = async (
  userId: UserId,
): Promise<IResolvedVal> => {
  try {
    const response = await userPermissionsHttp.get<UserPermissionsResponseItem[]>(
      `/user-permissions/user/${userId}`
    );

    return {
      success: true,
      data: response.data,
    };
  } catch {
    return {
      success: false,
      data: [],
    };
  }
};

export const resolverGetPermissionTypeUsers = async (
  permissionType: "REPORTING",
): Promise<{
  success: boolean;
  data: UsersByPermissionType;
}> => {
  try {
    const response = await userPermissionsHttp.get<ResponseUsersByPermission[]>(
      `/user-permissions/${permissionType}`
    );

    const output = response.data.reduce<UsersByPermissionType>(
      (acc, permissionExtended) => ({
        ...acc,
        [permissionExtended.name]: permissionExtended.users.map(user => ({
          id: user.id,
          fullName: user.fullName,
          typeValue: user.typeValue,
          facilities: user.assignedFacilities,
          role: {
            name: user.role.name,
          },
          facilityName:
            user.assignedFacilities.length > 0
              ? user.assignedFacilities[0].facility.name
              : "",
        })),
      }),
      {},
    );

    return {
      success: true,
      data: output,
    };
  } catch {
    return {
      success: false,
      data: {},
    };
  }
};

export const resolverMyPermissions = async (
): Promise<IResolvedVal> => {
  try {
    const response = await userPermissionsHttp.get<UserPermissionsResponseItem[]>(
      "/user-permissions"
    );

    return {
      success: true,
      data: response.data,
    };
  } catch {
    return {
      success: false,
      data: [],
    };
  }
};

export const resolverGrantPermission = async (
  userId: UserId,
  permission: TableUserDto.PermissionsEnum,
) => {
  try {
    await userPermissionsHttp.post(
      `/user-permissions/grant?permissionName=${permission}&userId=${userId}`,
    );

    return {success: true};
  } catch {
    return {success: false};
  }
};

export const resolverRevokePermission = async (
  userId: UserId,
  permission: TableUserDto.PermissionsEnum,
) => {
  try {
    await userPermissionsHttp.post(
      `/user-permissions/deny?permissionName=${permission}&userId=${userId}`,
    );

    return {success: true};
  } catch {
    return {success: false};
  }
};
