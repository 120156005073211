export const FACILITY_GO_LIVE_DATE_BLOCK = {
  id: "2fe6e3a9-8b8a-4d8f-9e7d-7c7d7c7d7c7d",
  rules: ["date", "max_length:10", "min_length:10"],
  type: "form:control",
  state: {
    visible: true,
    disabled: false,
    preFlight: false,
    clearable: false,
  },
  reference: {
    id: "facilityGoLiveDate",
    type: "TEXT",
    rules: ["date", "max_length:100"],
  },
};
