import React, {useCallback, useState} from "react";
import Alert from "react-s-alert";
import {usePrevious} from "react-use";
import {Formik, Form} from "formik";
import {omitBy, isUndefined} from "lodash";
import {validator} from "@reside/forms";
import {
  H1,
  Paragraph,
  Button,
  Modal,
  TextField,
  ScrollToError,
  ScrollableErrorArea,
  FloatingFieldLabel,
  ExternalLink,
} from "@reside/ui";
import {Approval} from "@reside/reside-api-admission";
import {connect} from "react-redux";

import {getPdfUrl} from "../../../../utils/url";
import {FormikWatchValuesChange} from "../../../../hooks/useFormikWatchValuesChange";
import {Spinner} from "../../../spinner";
import {PccModal} from "../../../pcc-modal";
import {usePointClickCareStatus} from "../../../../hooks/usePointClickCareStatus";
import {PccResidentAccountStatus} from "../../../../services/PccService";
import {readResidentPccAccountStatus} from "../../../../models/AdmissionModel.helpers";
import {ApproverSelectField} from "./ApproverSelectField";
import {ApprovalChecklist} from "./ApprovalChecklist";
import {ApprovalSignature} from "./ApprovalSignature";
import {ApproveModalProps, ChecklistValues, ApproveData} from "./utils/types";
import {
  pickUpdatedChecklistItems,
  dropFalseCheckedValues,
  getChecklistValidationRules,
} from "./utils/helpers";
import {ButtonWrapper, LineFields, Space, Content} from "./style";
import {AppState, select} from "../../../../store/store";
import {Facilities} from "../../../../models/types/AdmissionForm";
import {AdmissionsService} from "../../../../services/AdmissionsService";
import {AdmissionSignatureActions} from "../../../../utils/enums";

const emptyValues: Approval & {strokeData?: []} = {
  created: "",
  firstName: "",
  lastName: "",
  approverTitle: "" as any,
  signature: "",
  signedSections: [],
};

const ApproveModalRenderer = ({
  admission,
  facilities,
  initialValues = {},
  isOpen,
  onApprove,
  onRequestClose,
}: ApproveModalProps & {facilities: Facilities}) => {
  const {isPccEnabled} = usePointClickCareStatus(
    facilities,
    admission?.facilityId,
  );

  /**
   * Keep controlled values, so when form is reinitialized (some pcc document is uploaded)
   * we don't lose the form-local data.
   */
  const [formData, setFormData] = useState<Partial<Approval>>({});
  const [approveData, setApproveData] = useState<ApproveData>(null);
  const [isPccModalOpen, setIsPccModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {checklist = []} = admission ?? {};

  const prevInitialValues = usePrevious(initialValues);

  const updatedChecklistItems = pickUpdatedChecklistItems(
    initialValues as ChecklistValues,
    (prevInitialValues as ChecklistValues) ?? {},
  );

  const isPccAccountSkipped =
    readResidentPccAccountStatus(admission as any) ===
    PccResidentAccountStatus.SKIPPED;

  const isPccAccountActive =
    readResidentPccAccountStatus(admission as any) ===
    PccResidentAccountStatus.ACTIVE;

  const onClose = useCallback(() => {
    setFormData({});
    onRequestClose();
  }, [onRequestClose]);

  const handleApprove = useCallback(
    async approveData => {
      try {
        await onApprove(approveData);

        setIsLoading(false);
        Alert.success(
          `Admission was successfully approved. ${
            isPccEnabled && isPccAccountActive
              ? "PDF is being sent to PointClickCare"
              : ""
          }`,
        );

        AdmissionsService.saveSignatureMetadata(admission.id, {
          admission_id: admission.id,
          signature: approveData.approval.signature,
          metadata: {
            stroke_data: approveData.approval.strokeData,
            first_name: approveData.approval.firstName,
            last_name: approveData.approval.lastName,
          },
          action: AdmissionSignatureActions.SAVE_ADMISSION_SIGNATURE,
        });
      } catch {
        setIsLoading(false);
      }

      onClose();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isPccAccountActive, isPccEnabled, onApprove, onClose],
  );

  const handleSubmit = useCallback(
    async ({
      created,
      approverTitle,
      firstName,
      lastName,
      signature,
      signedSections,
      strokeData,
      ...approvalChecklist
    }: Approval & {strokeData?: []}) => {
      setIsLoading(true);

      const data = {
        approval: {
          approverTitle,
          created: new Date().toISOString(),
          firstName,
          lastName,
          signature,
          signedSections,
          strokeData,
        },
        checklist: checklist.map(checklistItem => {
          const approvalChecklistItem = (
            approvalChecklist as any as ChecklistValues
          )[checklistItem.id];

          return {
            ...checklistItem,
            ...approvalChecklistItem,
          };
        }),
      };

      setApproveData(data);
      if (isPccEnabled && isPccAccountSkipped) {
        setIsPccModalOpen(true);

        return;
      }

      await handleApprove(data);
    },
    [isPccEnabled, isPccAccountSkipped, handleApprove, checklist],
  );

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        ariaHideApp={false}
        Content={Content}
      >
        {({closeModal, Content, FooterButtons}) =>
          admission ? (
            <Formik<Approval>
              enableReinitialize
              initialValues={{
                ...emptyValues,
                ...omitBy(initialValues, isUndefined),
                ...omitBy(formData, isUndefined),
                ...updatedChecklistItems,
              }}
              validate={values =>
                validator.validateAll(dropFalseCheckedValues(values), {
                  firstName: "required",
                  lastName: "required",
                  approverTitle: "required",
                  signature: "required",
                  ...getChecklistValidationRules(checklist),
                })
              }
              onSubmit={values => handleSubmit(values)}
            >
              {({submitForm}) => (
                <>
                  <FormikWatchValuesChange onChangeValues={setFormData} />
                  <Content>
                    <H1>Admission Approval</H1>
                    <Paragraph>
                      You are about to approve this Admission. After your
                      approval, the Admission will become a valid contract
                      between the Resident and our Facility.
                    </Paragraph>
                    <Paragraph>
                      <ExternalLink href={getPdfUrl(admission.id)}>
                        Open Admission PDF preview.
                      </ExternalLink>
                    </Paragraph>
                    <Paragraph>
                      Please type your name, sign and confirm all documents
                      listed below. Your signature will be applied and valid for
                      each of these documents.
                    </Paragraph>
                    <Form data-test-id="approveAdmissionForm">
                      <ScrollToError elementSelector=".ReactModal__InnerContent" />
                      <ScrollableErrorArea>
                        <LineFields>
                          <TextField name="firstName" label="First Name" />
                          <TextField name="lastName" label="Last Name" />
                        </LineFields>
                        <ApproverSelectField />
                        <Space>
                          <ApprovalSignature
                            timeZone={admission.facilityTimeZoneId}
                          />
                        </Space>
                        <FloatingFieldLabel visible>
                          Checkboxes/Closing Checklist
                        </FloatingFieldLabel>
                        <Space>
                          <ApprovalChecklist
                            checklist={checklist}
                            admission={admission}
                          />
                        </Space>
                      </ScrollableErrorArea>
                    </Form>
                  </Content>
                  <FooterButtons>
                    <ButtonWrapper>
                      <Button
                        outline
                        color="primary"
                        disabled={isLoading}
                        onClick={closeModal}
                      >
                        Cancel
                      </Button>
                      <Button
                        testId="submitAdmissionButton"
                        color="primary"
                        type="submit"
                        disabled={isLoading}
                        onClick={submitForm}
                      >
                        {isLoading ? "Approving..." : "Approve"}
                      </Button>
                    </ButtonWrapper>
                  </FooterButtons>
                </>
              )}
            </Formik>
          ) : (
            <Spinner />
          )
        }
      </Modal>
      {/* This should probably not be here, but whole page is refreshed after approve. */}
      {isPccModalOpen && approveData && (
        <PccModal
          admissionId={admission.id}
          facilityId={admission.facilityId}
          isPccSkipped={isPccAccountSkipped}
          onRequestClose={() => {
            setApproveData(null);
            setIsPccModalOpen(false);
            handleApprove(approveData);
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  facilities: select.adminSession.facilities(state),
});

export const ApproveModal = connect(mapStateToProps)(ApproveModalRenderer);
