import React from "react";
import {useMutation} from "react-query";
import {Formik, Form} from "formik";
import Alert from "react-s-alert";
import {mapValues} from "lodash";
import {styled, Card, Button, DateFieldInput, TextField} from "@reside/ui";
import {validator} from "@reside/forms";
import {VerificationAnswersDto} from "@reside/reside-api-admission";

import {
  RESIDENT_FIRST_NAME,
  RESIDENT_LAST_NAME,
  RESIDENT_DATE_OF_BIRTH,
} from "../../../../models/AdmissionModel.helpers";
import {AdmissionEntryService} from "../../../../services/AdmissionEntryService";
import {buildResponseErrorMessage} from "../../../../services/api/errorHelpers";
import {isHighContrastColor} from "../../../../utils/helpers/isHighContrastColor";

export const VerifyAccessFormRenderer = ({
  isLoading,
  onSubmit,
}: {
  isLoading: boolean;
  onSubmit: (data: VerificationAnswersDto) => void;
}) => (
  <FormCard>
    <Formik<any>
      initialValues={{
        [RESIDENT_LAST_NAME]: "",
        [RESIDENT_FIRST_NAME]: "",
        [RESIDENT_DATE_OF_BIRTH]: "",
      }}
      validate={values =>
        validator.validateAll(values, {
          [RESIDENT_LAST_NAME]: "required",
          [RESIDENT_FIRST_NAME]: "required",
          [RESIDENT_DATE_OF_BIRTH]: ["required", "date", "date_in_past"],
        })
      }
      onSubmit={(data: VerificationAnswersDto) =>
        onSubmit(mapValues(data, value => value.trim()))
      }
    >
      {({submitForm}) => (
        <Form autoComplete="off">
          <Fieldset>
            <TextField name={RESIDENT_FIRST_NAME} label="Resident First Name" />
            <TextField name={RESIDENT_LAST_NAME} label="Resident Last Name" />
            <DateFieldInput
              name={RESIDENT_DATE_OF_BIRTH}
              label="Resident Date of Birth"
            />
          </Fieldset>
          <FullWidthButton
            color="primary"
            size="large"
            disabled={isLoading}
            onClick={submitForm}
          >
            {isLoading ? "Processing..." : "Continue with Admission"}
          </FullWidthButton>
        </Form>
      )}
    </Formik>
  </FormCard>
);

export const VerifyAnswersForm = ({
  onVerified,
  onUnauthorized,
}: {
  onVerified: (token: string) => void;
  onUnauthorized: () => void;
}) => {
  const {mutate, isLoading} = useMutation(
    AdmissionEntryService.enterAdmission,
    {
      onError: async (response: any) => {
        if (response?.status === 401) {
          Alert.error(
            "Your link has expired. Please request a new login link.",
          );
          onUnauthorized();
          return;
        } else {
          Alert.error(await buildResponseErrorMessage(response));
        }
      },
      onSuccess: ({data: {token}}) => {
        onVerified(token);
      },
    },
  );

  return <VerifyAccessFormRenderer isLoading={isLoading} onSubmit={mutate} />;
};

const Fieldset = styled.fieldset`
  margin-top: ${({theme}) => theme.space[4]}px;
  margin-bottom: ${({theme}) => theme.space[5]}px;

  label {
    margin-bottom: ${({theme}) => theme.space[3]}px;
  }
`;

const FullWidthButton = styled(Button)`
  border: 1px solid
    ${({theme}) => {
      const primary = theme.color.primary;

      if (isHighContrastColor(primary)) {
        return theme.color.textPrimary;
      }

      return primary;
    }};

  span {
    color: ${({theme}) => {
      const primary = theme.color.primary;

      if (isHighContrastColor(primary)) {
        return theme.color.textPrimary;
      }

      return theme.color.white;
    }} !important;
  }
  width: 100%;
`;

const FormCard = styled(Card)`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;

  @media (min-width: ${({theme}) => theme.breakpoint.lg}px) {
    min-width: 500px;
  }
`;
