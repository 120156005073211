var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import emotionStyled from "@emotion/styled";
import { ACTIVE_CUSTOM_THEME_KEY, isDefaultThemeActive, shouldApplyCustomStyle, } from "./utils";
export var breakpoint = {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
};
/*
 * Example:
 *
 * styled.div`
 *   ${({theme}) => theme.media.md} {
 *     display: block
 *   }
 * `
 */
var media = {
    sm: "@media screen and (min-width: ".concat(breakpoint.sm, "px)"),
    md: "@media screen and (min-width: ".concat(breakpoint.md, "px)"),
    lg: "@media screen and (min-width: ".concat(breakpoint.lg, "px)"),
    xl: "@media screen and (min-width: ".concat(breakpoint.xl, "px)"),
};
/*
 *  Example - use mobile first approach
 *  Idea taken from https://theme-ui.com/customize
 *
 *  padding: ${({theme}) => theme.space[1]}px //= padding: 4px
 *
 *  @media (min-width: ${({theme}) => theme.breakpoint.md}px) {
 *    padding: ${({theme}) => theme.space[2]}px //= padding: 8px
 *  }
 */
var space = [0, 4, 8, 12, 16, 24, 32, 48, 64, 96, 128, 256, 512];
var fontSize = [10, 12, 14, 16, 20, 24, 32, 48, 64];
var color = {
    primary: "#2bb7d9",
    darkBlue100: "#203c63",
    darkBlue90: "#243745",
    darkBlue80: "#455a64",
    darkBlue70: "#627691",
    darkBlue60: "#9cb6d3",
    darkBlue40: "#a6b1c1",
    darkBlue30: "#c8d7ed",
    darkBlue10: "#e8ebef",
    darkBlue5: "#fafcff",
    lightBlue10: "#f2f6fa",
    pink100: "#ff5376",
    orange100: "#ff785e",
    deepOrange100: "#ff3a45",
    deepOrange300: "#a10009",
    slateBlue100: "#8482f9",
    golden100: "#f9d947",
    yellow100: "#ffe746",
    green100: "#1bd760",
    mint100: "#6cd7c3",
    mint70: "#97e2d5",
    gray100: "#a8b6ca",
    gray80: "#ccc",
    gray20: "#bdc4cc",
    gray10: "#ebedf0",
    gray5: "#f5f6f7",
    errorRed: "#eb4f4f",
    borderLightGray: "#ecf0f8",
    borderGray: "#d0d9e2",
    white: "#ffffff",
    shadow: "rgba(0, 0, 0, 0.25)",
    // Branding colors for resident experience - default reside color palette
    backgroundDefault: "#FCFCFC",
    backgroundLight: "#FFFFFF",
    fillSuccess: "#28dcaa",
    textSuccess: "#203c63",
    fillInfo: "#bcf2ff",
    textInfo: "#203c63",
    textPrimary: "#404040",
};
export var fontWeight = {
    normal: 400,
    medium: 500,
    bold: 600,
    heavy: 700,
};
var themeStyledSystem = {
    breakpoints: Object.values(breakpoint).map(function (item) { return "".concat(item, "px"); }),
    colors: color,
    fontWeights: fontWeight,
    space: space,
    fontSizes: fontSize,
};
export var theme = __assign(__assign({}, themeStyledSystem), { media: media, 
    // Extend current theme with active theme colors.
    color: shouldApplyCustomStyle()
        ? __assign(__assign({}, color), (_a = JSON.parse(isDefaultThemeActive()
            ? "{}"
            : localStorage.getItem(ACTIVE_CUSTOM_THEME_KEY))) === null || _a === void 0 ? void 0 : _a.colors)
        : color, breakpoint: breakpoint, fontWeight: fontWeight, space: space, fontSize: fontSize });
/**
 * Re-export as named export.
 */
export var styled = emotionStyled;
