import React, {memo} from "react";
import Alert from "react-s-alert";
import {Formik} from "formik";
import {format} from "date-fns";
import {useMutation} from "react-query";
import {validator} from "@reside/forms";
import {
  styled,
  FullScreenModal,
  FullScreenModalProps,
  TextAreaField as BaseTextAreaField,
  Button as BaseButton,
  Caption,
  H1,
  Paragraph as BaseParagraph,
  Text,
  Markdown,
  theme,
} from "@reside/ui";
import {DATE_FORMAT} from "@reside/ui/dist/date-picker/date";
import {AdmissionFlag} from "@reside/reside-api-admission";

import {Avatar} from "../../../avatar";
import {Footer} from "../../../footer";
import {AdmissionContextConsumer} from "../../AdmissionContext";
import {ReactComponent as HelpSvg} from "./help.svg";

import "./FlagForm.scss";

export const HelpControlModal = () => {
  return (
    <AdmissionContextConsumer>
      {({
        currentSlide,
        draft,
        draftActions,
        uiState: {flagUiIsOpen, textSize},
        uiActions: {closeFlagUi},
      }) => (
        <HelpControlModalRenderer
          isOpen={flagUiIsOpen}
          flag={
            currentSlide.state.flag
              ? (draft.flags[currentSlide.state.flag as any] as AdmissionFlag)
              : undefined
          }
          formDisabled={draft.computed.readOnly}
          onFlag={({comment}) =>
            draftActions.flagSlide({comment, slideId: currentSlide.id})
          }
          onUnflag={flagId => draftActions.unflagSlide(flagId)}
          onClose={closeFlagUi}
          textSize={textSize}
        />
      )}
    </AdmissionContextConsumer>
  );
};

type Values = Pick<AdmissionFlag, "comment">;

type Props = Readonly<{
  flag?: AdmissionFlag;
  formDisabled?: boolean;
  isOpen: boolean;
  textSize: number;
  onClose: () => void;
  onFlag: (values: Values) => Promise<void>;
  onUnflag: (flagId: string) => Promise<void>;
}> &
  Omit<FullScreenModalProps, "children">;

export const HelpControlModalRenderer = memo(
  ({
    flag,
    isOpen,
    formDisabled,
    textSize,
    onClose,
    onFlag,
    onUnflag,
    ...props
  }: Props) => {
    const {mutate: flagSlide, isLoading: isCreatingFlag} = useMutation(onFlag, {
      onSuccess: () => {
        Alert.success("Your comment was successfully saved!");
      },
      onError: () => {
        Alert.error("Failed to create flag.");
      },
      onSettled: () => {
        onClose();
      },
    });
    const {mutate: unflagSlide, isLoading: isRemovingFlag} = useMutation(
      onUnflag,
      {
        onSuccess: () => {
          Alert.success("Your comment was successfully removed!");
        },
        onError: () => {
          Alert.error("Failed to remove flag.");
        },
        onSettled: () => {
          onClose();
        },
      },
    );

    const isLoading = isCreatingFlag || isRemovingFlag;

    return (
      <FullScreenModal {...props} isOpen={isOpen} onRequestClose={onClose}>
        {() => (
          <Formik<Values>
            initialValues={{comment: flag ? flag.comment : ""}}
            validate={values =>
              validator.validateAll(values, {comment: "required"})
            }
            onSubmit={({comment}) => {
              if (!flag) {
                flagSlide({
                  comment,
                });
              } else {
                unflagSlide(flag.id);
              }
            }}
          >
            {formik => (
              <>
                <Container textSize={textSize}>
                  <div>
                    <svg
                      style={
                        {
                          "--primary-color": theme.color.primary,
                          "--accent-color": theme.color.fillInfo,
                        } as React.CSSProperties
                      }
                    >
                      <HelpSvg />
                    </svg>
                    <H1 fontSize="3em">Request Help</H1>
                  </div>
                  <Paragraph>
                    If you would like to request further assistance or require
                    further explanation of the content on this slide, please
                    explain your concern below and someone from our admissions
                    staff will contact you shortly.
                  </Paragraph>
                  {flag ? null : (
                    <TextAreaField
                      autoResize
                      disabled={formDisabled}
                      name="comment"
                      id="flag-comment-input"
                      label="Please describe your question or concern here"
                      textSize={textSize}
                    />
                  )}
                  {flag ? (
                    <div className="flagform-0-25">
                      <Avatar size={48} name={`${flag.author}`} />
                      <div className="flagform-0-26">
                        <div className="flagform-0-27">
                          <Text
                            fontSize={4}
                            fontWeight="bold"
                            color="darkBlue80"
                          >
                            {flag.author}
                          </Text>
                          <Caption className="flagform-0-28">
                            {format(new Date(flag.created), DATE_FORMAT.DAY)}
                          </Caption>
                        </div>
                        <div className="flagform-0-29">
                          <Text fontSize={3}>
                            {/* Markdown used to render new lines from the textarea */}
                            <Markdown source={flag.comment} />
                          </Text>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </Container>
                <Footer
                  right={
                    <Button
                      size="large"
                      disabled={isLoading || formDisabled}
                      color={flag ? "danger" : "primary"}
                      onClick={formik.submitForm}
                      testId={flag ? "remove-flag-button" : "add-flag-button"}
                      textSize={textSize}
                    >
                      {isLoading
                        ? "Processing..."
                        : flag
                        ? "Remove flag"
                        : "Confirm"}
                    </Button>
                  }
                />
              </>
            )}
          </Formik>
        )}
      </FullScreenModal>
    );
  },
);

HelpControlModal.defaultProps = {
  formDisabled: false,
};

const Container = styled.div<{textSize: number}>`
  padding: ${({theme}) => theme.space[5]}px;
  margin-top: auto;
  margin-bottom: auto;

  ${({textSize}) => `font-size: ${textSize * 0.875}rem;`}

  @media (min-width: ${({theme}) => theme.breakpoint.md}px) {
    padding: ${({theme}) => theme.space[7]}px;
  }

  @media (min-width: ${({theme}) => theme.breakpoint.lg}px) {
    padding: ${({theme}) => theme.space[9]}px;
  }

  img[alt="Help"] {
    width: 5.375em;
  }
`;

const Paragraph = styled(BaseParagraph)`
  text-align: justify;
  text-justify: auto;
`;

const TextAreaField = styled(BaseTextAreaField)<{textSize: number}>`
  overflow: hidden;
  resize: none;
  padding-right: 25px;

  &[name="comment"] {
    min-height: ${({textSize}) => (3.5 * textSize) / textSize}em;
  }

  @media (min-width: ${({theme}) => theme.breakpoint.sm}px) {
    &[name="comment"] {
      min-height: 2.625em;
    }
  }

  @media (min-width: ${({theme, textSize}) =>
      theme.breakpoint.xl * (textSize - 0.9)}px) {
    &[name="comment"] {
      height: 2.125em;
    }
  }
`;

const Button = styled(BaseButton)<{textSize: number}>`
  &:hover {
    transition-delay: 100ms;
    > span {
      transition-delay: inherit;
    }
  }

  &.large {
    --translate-x: -${({textSize}) => ((textSize - 1) / 4) * 75}px;
    --scale: ${({textSize}) => (textSize - 1) / 4 + 1};

    transform: translateX(var(--translate-x)) scale(var(--scale));
    &:hover {
      transform: translate(var(--translate-x), -${({textSize}) => textSize}px)
        scale(var(--scale));
    }
  }
`;
