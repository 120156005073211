import {styled} from "@reside/ui";

export const OneThemeWrapper = styled.div`
  padding-left: 8px;

  display: grid;
  grid-template-rows: auto auto;
  height: auto;
  max-width: 240px;
`;

export const ThemeContent = styled.div<{active: boolean}>`
  border: 3px solid
    ${({active, theme}) =>
      active ? theme.color.primary : theme.color.borderLightGray};
  border-radius: 4px;
  padding: 16px;

  &:hover {
    cursor: pointer;
    background-color: ${({theme}) => theme.color.primary}20;
    transition: all 0.1s ease-in-out;
  }
`;

export const ThemeName = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
`;

export const ActiveLabel = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
  background: ${({theme}) => theme.color.green100};
  color: ${({theme}) => theme.color.white};
  font-size: 16px;
  font-weight: 500;
`;
