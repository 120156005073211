import {useMutation, useQueryClient} from "react-query";
import {
  BrandingService,
  CreateThemeRequest,
} from "../../../services/BrandingService";
import {useParams, useHistory} from "react-router";
import Alert from "react-s-alert";

export const useCreateBrandingTheme = () => {
  const {organizationId} = useParams<{organizationId: string}>();
  const history = useHistory();
  const queryClient = useQueryClient();

  return useMutation(
    async (data: CreateThemeRequest) => {
      const response = await BrandingService.createTheme(organizationId, data);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({queryKey: ["themes"]});
        history.push(`/admin/organizations/${organizationId}/edit`);
        Alert.success("Theme created successfully");
      },
      onError: () => {
        Alert.error("Failed to create theme");
      },
    },
  );
};
