import {Text, styled, theme} from "@reside/ui";

export const TextLink = styled((props: any) => (
  <Text
    style={{color: theme.color.primary}}
    lineHeight="1.75em"
    fontWeight="bold"
    {...props}
  >
    click here
  </Text>
))`
  cursor: pointer;
  text-decoration: underline;
`;
