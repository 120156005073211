import React, {ReactElement, useMemo} from "react";
import {Form, Formik, useFormikContext} from "formik";
import {useDeepCompareEffect, usePrevious} from "react-use";
import {useLocation} from "react-router-dom";
import qs from "qs";
import {
  Blocks,
  computeChildrenBlock,
  extractFormFields,
  extractInitialFormValuesForBlock,
  getBlockName,
  getValidationConfig,
  isFormControlBlock,
  isSignatureReference,
  Values,
} from "@reside/forms";
import {ScrollableErrorArea, ScrollToError, styled} from "@reside/ui";
import {DocumentTitle} from "../../document-title";
import {UseScrollToTopOnHistoryChange} from "../../../hooks/useScrollToTopOnHistoryChange";
import {AdmissionStatus} from "../../../services/AdmissionsService";
import {getPreflightedAnswers} from "../../../models/AdmissionsModel.helpers";
import {t} from "../../i18n-provider";
import {FullscreenBackground} from "../../fullscreen-background";
import {Draft, DraftActions, SlideBlock} from "../model";
import {BlockSlide} from "../block-slide";
import {NavbarTop} from "../navbar-top";
import {NavbarBottom} from "../navbar-bottom";
import {SlideInfoBar} from "../slide-info-bar";
import {useAdmissionContext} from "../AdmissionContext";
import {ScrollContextProvider} from "../scroll-context";
import {TrackedScrollReminder} from "../tracked-scroll-reminder";
import {TocControlModal} from "../toc-control";
import {BackdropSpinner} from "../../spinner";
import {cleanupSlideValues} from "../../admin-admission-explorer/helpers";

const StyledScrollableErrorArea = styled(ScrollableErrorArea)<{
  textSize: number;
}>`
  flex: 1;
  z-index: 1;
  overflow: auto;
  font-size: ${({textSize}) => `${16 * textSize}px`};
  margin-right: 5px;
`;

const NavBarTopWrapper = styled.div`
  z-index: 2;
`;

const NavBarBottomWrapper = styled.div<{textSize: number}>`
  z-index: 2;
  width: 100%;
  > footer {
    --translate-x: ${({textSize}) => ((textSize - 1) / 4) * 75}px;
    --scale: ${({textSize}) => (textSize - 1) / 4 + 1};
    @media (min-width: 768px) {
      padding-block: 1rem;
      --normal: 1;
      --invert: -1;
    }
    [data-test-id="go-back-button"] {
      padding-right: ${({textSize}) => textSize}rem;
    }
    [data-test-id="pause-button"],
    [data-test-id="go-back-button"] {
      transform: translateX(calc(var(--translate-x) * var(--invert, 1)))
        scale(var(--scale));
      &:hover {
        transform: translate(
            calc(var(--translate-x) * var(--invert, 1)),
            -${({textSize}) => textSize}px
          )
          scale(var(--scale));
      }
    }
    [type="submit"].large {
      transform: translateX(calc(var(--translate-x) * var(--normal, -1)))
        scale(var(--scale));
      &:hover {
        transform: translate(
            calc(var(--translate-x) * var(--normal, -1)),
            -${({textSize}) => textSize}px
          )
          scale(var(--scale));
      }
    }
    [type="submit"].large,
    [data-test-id="pause-button"],
    [data-test-id="go-back-button"] {
      &:hover {
        transition-delay: 100ms;
        > i,
        > span {
          transition-delay: inherit;
        }
      }
    }
  }
`;

/**
 * Context-like data describing the state of the current slide and admission.
 */
export type SlideShowProps = Readonly<{
  isFirstSlide: boolean;
  isLastSlide: boolean;
  isSignSlide: boolean;
  isLogoutSlide: boolean;
  isAdmissionCompleted: boolean;
}>;

export const SlideShowRenderer = (props: SlideShowProps) => {
  const {
    draft,
    currentSlide,
    uiState,
    draftActions,
    uiActions,
    hasClearHiddenFieldsFlag,
  } = useAdmissionContext();
  const {search} = useLocation();

  const {
    id: slideId,
    children,
    state: {complete: isSlideComplete},
  } = currentSlide;
  const {template, answers} = draft;

  const preflightedAnswers = useMemo(
    () => getPreflightedAnswers(draft.instance.answers),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const blocks = useMemo(
    () =>
      children.map(node =>
        computeChildrenBlock({
          node,
          answers,
          template,
          computed: draft.computed,
          preflightedAnswers,
        }),
      ),
    [draft, template, answers, preflightedAnswers, children],
  );

  const initialValues = extractInitialFormValuesForBlock({
    values: answers,
    children: blocks,
  });

  const validate = createValidationFn(draft, {
    ...currentSlide,
    children: blocks as any,
  });

  /**
   * Based on initial errors the SlideInfoBar displays a 'Done' message or slide-specific instruction.
   */
  const initialErrors = useMemo(
    () => validate(initialValues),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentSlide.id],
  );

  const isAdmissionSigned = draft.instance.status === AdmissionStatus.SIGNED;

  return (
    <>
      <DocumentTitle
        title={`Reside - Admission - ${t(currentSlide.title.translationKey)}`}
      />
      <UseScrollToTopOnHistoryChange selector=".js-scroll-to-top" />
      <Formik
        key={currentSlide.id}
        initialValues={initialValues}
        initialTouched={qs.parse(search)?.validate ? initialErrors : undefined}
        initialErrors={initialErrors}
        validate={validate}
        onSubmit={async values => {
          if (isAdmissionSigned) {
            /**
             * When the admission is signed, we no longer save answers but just traverse the slides.
             */
            return uiActions.setNextSlideIndex();
          }

          /**
           * Submit is called only when slide is without errors.
           * We mark the slide as completed.
           * Note: the condition is just pre-caution, we never want to complete the last slide while in the SlideShow.
           *       Only the sign action will mark the last slide as completed.
           */
          if (!currentSlide.state.last && !currentSlide.state.flag) {
            await draftActions.completeSlide(currentSlide.id);
          }

          await uiActions.setNextSlideIndex();

          const valuesToSubmit = hasClearHiddenFieldsFlag
            ? cleanupSlideValues(template.references, values)
            : values;

          draftActions.slideOnSubmit({values: valuesToSubmit});
        }}
      >
        <>
          <AutoSetAnswersExceptSignatures
            slideId={slideId}
            isSlideComplete={isSlideComplete}
            blocks={blocks}
            removeCompleteSlide={draftActions.removeCompleteSlide}
            setAnswers={draftActions.setAnswers}
          />
          <ScrollToError offsetTop={72} offsetBottom={190} />
          <ScrollContextProvider bottomTolerance={25}>
            {scrollRef => (
              <Form autoComplete="off">
                <FullscreenBackground
                  backgroundUrl={currentSlide.backgroundUrl}
                >
                  <NavBarTopWrapper>
                    <NavbarTop />
                  </NavBarTopWrapper>
                  {currentSlide.infoMessage && (
                    <SlideInfoBar infoMessage={currentSlide.infoMessage} />
                  )}
                  <StyledScrollableErrorArea
                    ref={scrollRef}
                    textSize={uiState.textSize}
                    className="js-scroll-to-top"
                  >
                    <BlockSlide {...currentSlide} children={blocks as any} />
                  </StyledScrollableErrorArea>
                  <TrackedScrollReminder />
                  <NavBarBottomWrapper textSize={uiState.textSize}>
                    <NavbarBottom {...props} />
                  </NavBarBottomWrapper>
                </FullscreenBackground>
              </Form>
            )}
          </ScrollContextProvider>
          {/**
           * Note: the ToC modal (unlike others in RE) must be within Formik context, because the modal displays customized ToC from JSONs which can have paragraphs which read values from formik...
           */}
          <TocControlModal />
        </>
      </Formik>
    </>
  );
};

export const SlideShow = () => {
  const {draft, uiState, currentSlide, loadingPatchAdmission} =
    useAdmissionContext();

  return (
    <BackdropSpinner active={loadingPatchAdmission}>
      <SlideShowRenderer
        isFirstSlide={uiState.slideIdx === 0}
        isLastSlide={currentSlide.state.last}
        isSignSlide={
          currentSlide.state.last &&
          draft.instance.status === AdmissionStatus.IN_PROGRESS
        }
        isLogoutSlide={
          currentSlide.state.last &&
          draft.instance.status !== AdmissionStatus.IN_PROGRESS
        }
        isAdmissionCompleted={
          (draft.computed.completionPercentage === 100 &&
            draft.instance.flags.length === 0) ||
          draft.computed.readOnly
        }
      />
    </BackdropSpinner>
  );
};

type AutoSetAnswersExceptSignatureProps = Readonly<{
  blocks: ReadonlyArray<Blocks>;
  slideId: string;
  isSlideComplete: boolean;
}> &
  Pick<DraftActions, "removeCompleteSlide" | "setAnswers">;

/**
 * Formik does not have onChange, so we detect change manually with deepCompare.
 */
const useAutoSetAnswersExceptSignatures = ({
  blocks,
  slideId,
  isSlideComplete,
  removeCompleteSlide,
  setAnswers,
}: AutoSetAnswersExceptSignatureProps) => {
  const {values} = useFormikContext<Values>();
  const prevValues = usePrevious(values);

  const signatures = useMemo(
    () =>
      extractFormFields(blocks)
        .filter(
          block =>
            isFormControlBlock(block) && isSignatureReference(block.reference),
        )
        .map(getBlockName),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [slideId],
  );

  useDeepCompareEffect(() => {
    // Ignore first render
    if (!prevValues) {
      return;
    }

    /**
     * When a change is made to some of the answers, we invalidate the completed slide.
     * It's no longer completed, the slide has potentially invalid, dirty state.
     */
    if (isSlideComplete) {
      removeCompleteSlide(slideId);
    }

    if (
      signatures.some(fieldName => prevValues[fieldName] !== values[fieldName])
    ) {
      /**
       * We ignore changes in signatures, so the UI does not update.
       * The changes in signatures are picked only when on form submit.
       */

      return;
    }

    setAnswers(values);
  }, [values]);
};

const AutoSetAnswersExceptSignatures = ({
  children = () => null,
  ...props
}: AutoSetAnswersExceptSignatureProps & {
  children?: (props: void) => ReactElement;
}) => {
  useAutoSetAnswersExceptSignatures(props);
  return children();
};

/**
 * Completed admission has readOnly state so we don't validate the slides.
 * When slide is flagged, user can continue the slide with invalid state.
 */
const skipValidation = (draft: Draft, slide: SlideBlock) =>
  draft.computed.readOnly || !!slide.state.flag;

const createValidationFn =
  (draft: Draft, slide: SlideBlock) =>
  (values?: any): any => {
    const {children} = slide;
    const {validate} = getValidationConfig({
      children,
      answers: values,
    });

    if (!skipValidation(draft, slide)) {
      return validate(values);
    }

    return {};
  };
