import {useMutation} from "react-query";
import {BrandingService} from "../../../services/BrandingService";
import {ACTIVE_CUSTOM_THEME_KEY, DEFAULT_THEME} from "../constants";

export const useFetchActiveThemeWithAdmissionToken = () => {
  const {
    data: activeTheme,
    isLoading,
    mutate,
  } = useMutation(
    ["activeThemeWithAdmissionToken"],
    async ({token}: {token: string}) => {
      const response = await BrandingService.getActiveThemeWithAdmissionToken(
        token,
      );

      if (
        // Fetch completed
        response.data !== undefined
      ) {
        if (
          // No active theme - theme is default
          response.data === null
        ) {
          localStorage.setItem(ACTIVE_CUSTOM_THEME_KEY, DEFAULT_THEME.id); // Remove active custom theme so default theme is used
        } else {
          localStorage.setItem(
            ACTIVE_CUSTOM_THEME_KEY,
            JSON.stringify(response.data), // Store active custom theme
          );
        }
      }

      return response.data;
    },
    {
      onSuccess: () => {
        window.location.reload();
        sessionStorage.setItem("theme-fetched", "true");
      },
    },
  );

  return {
    activeCustomBrandingTheme: activeTheme,
    isLoading,
    mutate,
  };
};
