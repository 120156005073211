import {useQuery} from "react-query";

import {
  FeatureFlagsService
} from "../../../services/FeatureFlagsService";

export const useFetchAllOrganizationFeatureFlags = () =>
  useQuery(
    "useFetchAllOrganizationFeatureFlags",
    async () => {
      const {data} =
        await FeatureFlagsService.get_organization_feature_flags_types();
      return data;
    },
    // Always keep data in cache to avoid re-fetching this static data
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );
