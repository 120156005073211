import {useMutation, useQueryClient} from "react-query";
import {
  BrandingService,
  CreateThemeRequest,
} from "../../../services/BrandingService";
import {useParams, useHistory} from "react-router";
import Alert from "react-s-alert";

export const useUpdateBrandingTheme = () => {
  const {organizationId, themeId} = useParams<{
    organizationId: string;
    themeId: string;
  }>();
  const history = useHistory();
  const queryClient = useQueryClient();

  return useMutation(
    async (data: CreateThemeRequest) => {
      const response = await BrandingService.updateTheme(
        organizationId,
        themeId,
        data,
      );
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["themes", "single-theme"]);
        history.push(`/admin/organizations/${organizationId}/edit`);
        Alert.success("Theme updated successfully");
      },
      onError: () => {
        Alert.error("Failed to update theme");
      },
    },
  );
};
