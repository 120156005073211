import React from "react";
import {
  PreviewPageAdmissionPreludeRadioGroupWrapper,
  PreviewPageAdmissionPreludeWrapper,
} from "./styles";
import {Button, H1, RadioGroupField, theme} from "@reside/ui";
import {PreviewPageProps} from "./types";

/**
 * This component serves as a preview for customization.
 *
 * This component is purely for preview purposes and does not include any
 * functional logic.
 *
 */
export const PreviewPageAdmissionPrelude = ({
  color = theme.color.primary,
  backgroundDefault = theme.color.backgroundDefault,
}: Pick<PreviewPageProps, "color"> & {
  backgroundDefault?: string;
}): JSX.Element => (
  <PreviewPageAdmissionPreludeWrapper
    color={color}
    backgroundDefault={backgroundDefault}
  >
    <PreviewPageAdmissionPreludeRadioGroupWrapper color={color}>
      <H1>Tell us who you are</H1>
      <RadioGroupField
        name="previewPageAdmissionPrelude"
        options={[
          {label: "I am the Resident", value: true},
          {label: "I am the Resident Representative", value: false},
        ]}
      />
      <Button
        color="primary"
        size="large"
        flat
        style={{marginTop: "32px", marginLeft: "auto"}}
      >
        Continue
      </Button>
    </PreviewPageAdmissionPreludeRadioGroupWrapper>
  </PreviewPageAdmissionPreludeWrapper>
);
