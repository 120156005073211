import {useQuery} from "react-query";
import {useParams} from "react-router";

import {BrandingService} from "../../../services/BrandingService";
import {sortThemesByCreationDate} from "../utils";
import {DEFAULT_THEME} from "../constants";

interface ThemeQueryParams {
  organizationId: string;
}

interface FetchThemesOptions {
  organizationIdByUser?: string;
  onlyActive?: boolean;
  refetchOnMount?: boolean;
}

export const useFetchAllBrandingThemes = ({
  organizationIdByUser,
  onlyActive,
  refetchOnMount = true,
}: FetchThemesOptions = {}) => {
  // Get organizationId from URL parameters if not provided directly
  const {organizationId: organizationIdFromParams} =
    useParams<ThemeQueryParams>();

  // Prioritize organizationId from user state over URL params
  const organizationId = organizationIdByUser || organizationIdFromParams || ""; // organizationIdFromParams is used because reside admin doesn't have organizationIdByUser in the state

  return useQuery(
    ["themes", organizationId, onlyActive],
    async () => {
      // Fetch themes from API. If onlyActive is true, fetch only active theme.
      const response = await BrandingService.getThemes(
        organizationId,
        onlyActive ? {query: "active"} : {},
      );
      const themes = response.data;

      // Create a sorted copy of themes to maintain immutability
      const sortedThemes = sortThemesByCreationDate([...themes.content]);

      // Default theme should be inactive if any custom theme is active
      const hasActiveCustomTheme = sortedThemes.some(theme => theme.active);
      const defaultTheme = {...DEFAULT_THEME, active: !hasActiveCustomTheme};

      // Return themes with default theme always first in the list
      return {
        ...themes,
        content: [defaultTheme, ...sortedThemes],
      };
    },
    {
      // Prevent query from running without an organizationId
      enabled: Boolean(organizationId),
      refetchOnMount,
    },
  );
};
