import {jsPDF} from "jspdf";
import {autoTable} from "jspdf-autotable";
import {saveAs} from "file-saver";
import {format} from "date-fns";
import {identity} from "lodash";
import Alert from "react-s-alert";
import {DATE_FORMAT, LOCAL_TIME_ZONE} from "@reside/ui/dist/date-picker/date";

import {
  getCheckListItemKey,
  isReportingTypeCheckListItems,
  isReportsDataPath,
  ReportsDataPath,
  ReportsTypeNames,
} from "../../store/reporting-reports/reporting-reports.common";
import {
  AnalyticsType,
  AnalyticsTypeNames,
  AnalyticsTypeWithoutResolution,
  AnyDataPath,
  getRequestUnitTimestamps,
  isAnalyticsDataPath,
  isWithResolution,
  Resolution,
} from "../../store/reporting/reporting.common";
import {http} from "../../services/api";
import {numberToPercent} from "../../pages/page-reporting/analytics/helpers";
import {
  formatData,
  formatHeaders,
  formatUnderscoreStrings,
} from "./ReportingBlock.helpers";
import {mapRate, mapStatusNames} from "../../pages/page-reporting/analytics";

export enum FileExtension {
  CSV = "csv",
  PDF = "pdf",
  XLSX = "xlsx",
}

enum ContentType {
  CSV = "text/csv;charset=utf-8",
  JSON = "application/json;charset=UTF-8",
  XLSX = "application/vnd.ms-excel",
}

const PDF_SETTINGS = {
  format: "a4",
  unit: "px",
  fontSize: 10,
  textColor: 40,
  fontStyle: "normal",
  margin: 25,
} as const;

const createFilename = (dataPath: AnyDataPath, extension: FileExtension) => {
  let filenameParts = [];

  if (isAnalyticsDataPath(dataPath)) {
    filenameParts.push(
      AnalyticsTypeNames[dataPath.type].toLowerCase().replace(/\s/g, "-"),
    );

    if (isWithResolution(dataPath)) {
      switch (dataPath.query.resolution) {
        case Resolution.DAY:
          filenameParts.push("daily");
          break;

        case Resolution.MONTH:
          filenameParts.push("monthly");
          break;

        case Resolution.YEAR:
          filenameParts.push("yearly");
          break;
      }
    }
  } else if (isReportsDataPath(dataPath)) {
    const reportName = isReportingTypeCheckListItems(dataPath)
      ? dataPath.checklistItem.description
      : ReportsTypeNames[(dataPath as ReportsDataPath).type];

    filenameParts.push(reportName.toLowerCase().replace(/\s/g, "-"));
  }

  filenameParts = [...filenameParts, dataPath.query.from, dataPath.query.to];

  return encodeURIComponent(`${filenameParts.join("_")}.${extension}`);
};

interface TableData {
  headers: any[];
  data: any[];
}

// TODO: functional refactor
const findFirstAggregatedSegment = (segments: any[]): any => {
  for (const segment of segments) {
    if (Object.keys(segment.aggregations || {}).length) {
      return segment;
    }

    if (segment.segments) {
      const nestedSegment = findFirstAggregatedSegment(segment.segments);

      if (nestedSegment) {
        return nestedSegment;
      }
    }
  }

  return null;
};

export const parseLineChartData = (jsonData: any) => {
  return {
    headers: Object.keys(jsonData.data[0]),
    data: jsonData.data.map(Object.values),
  };
};

export const parseOneLevelAggregationData = (
  jsonData: any,
  typeFormatter: (val: any) => string = identity,
  valueFormatter: (val: any) => any = identity,
) => {
  const headers = [
    "Name",
    ...Object.keys(findFirstAggregatedSegment(jsonData.segments).aggregations),
  ];

  const data = jsonData.segments.map((segment: any) => {
    return [
      typeFormatter(segment.segmentInfo.name),
      ...Object.values(segment.aggregations).map(valueFormatter),
    ];
  });

  return {headers, data};
};

export const parseNestedAggregationData = (
  jsonData: any,
  formatNameColumn: (val: any) => string = identity,
  formatValueColumn: (val: any) => string = identity,
  formatAggregatorColumn: (val: any) => string = identity,
): TableData => {
  const data = jsonData.segments.flatMap((segment: any) => {
    return segment.segments.map((nestedSegment: any) => {
      return [
        formatNameColumn(nestedSegment.segmentInfo.name),
        formatValueColumn(Object.values(nestedSegment.aggregations)[0]),
        formatAggregatorColumn(segment.segmentInfo.name),
      ];
    });
  });
  const headers = [
    "Name",
    ...Object.keys(findFirstAggregatedSegment(jsonData.segments).aggregations),
    jsonData.segments[0].segmentInfo.type,
  ];

  return {headers, data};
};

const parserPicker = (jsonData: any, dataPath: AnyDataPath) => {
  switch (dataPath.type) {
    case AnalyticsTypeWithoutResolution.payerSourceAge65_75:
    case AnalyticsTypeWithoutResolution.payerSourceAge75_85:
      return parseNestedAggregationData(
        jsonData,
        undefined,
        numberToPercent,
        formatUnderscoreStrings,
      );
    case AnalyticsTypeWithoutResolution.payerSourceAge85:
    case AnalyticsTypeWithoutResolution.payerSourceAge65_75ByCountry:
    case AnalyticsTypeWithoutResolution.payerSourceAge75_85ByCountry:
    case AnalyticsTypeWithoutResolution.payerSourceAge85ByCountry:
      return parseNestedAggregationData(
        jsonData,
        undefined,
        undefined,
        formatUnderscoreStrings,
      );
    case AnalyticsType.approvedAndArchivedAdmissions:
    case AnalyticsType.totalAdmissions:
    case AnalyticsType.representativeRate:
    case AnalyticsType.readmissionRate:
    case AnalyticsType.arbitrationAgreementRate:
    case AnalyticsType.managedMedicaidRate:
    case AnalyticsType.missingMedicareRate:
    case AnalyticsType.timeToCloseFromSignature:
    case AnalyticsType.flagsAge:
      return parseLineChartData(jsonData);
    case AnalyticsType.admissionsFacilityCompletionRate:
    case AnalyticsType.admissionsStaffCompletionRate:
    case AnalyticsTypeWithoutResolution.admissionsCompletionOverall:
    case AnalyticsType.representativeRateByFacility:
    case AnalyticsType.readmissionRateByFacility:
    case AnalyticsType.managedMedicaidRateByFacility:
    case AnalyticsType.timeToCloseFromSignatureByFacility:
      return parseOneLevelAggregationData(jsonData, identity, mapRate);
    case AnalyticsType.timeByStage:
    case AnalyticsType.flagsBySlide:
      return parseOneLevelAggregationData(jsonData);
    case AnalyticsTypeWithoutResolution.admissionsCompletionOnTime:
      return parseOneLevelAggregationData(jsonData, mapStatusNames);
    default:
      throw new Error("Unknown chart type!" + dataPath.type);
  }
};

const parseAnalyticsData = (jsonData: any, dataPath: AnyDataPath) => {
  const tableData = parserPicker(jsonData, dataPath);

  return {
    headers: formatHeaders(tableData.headers),
    data: formatData(tableData.data),
  };
};

export const fetchExportData = (
  contentType: ContentType,
  dataPath: AnyDataPath,
  config = {},
) => {
  if (!dataPath) {
    return;
  }
  const {sortDirection, sortField, resolution, from, to, facilities} =
    dataPath.query as any;

  return http.post<string>(
    getCheckListItemKey(dataPath),
    {
      ...getRequestUnitTimestamps(from, to),
      facilityIds: facilities ?? [],
      dataResolution: resolution,
      pageable: {
        size: 9999,
        direction: sortDirection,
        properties: [sortField],
      },
      localTimeZone: LOCAL_TIME_ZONE,
    },
    {
      headers: {
        Accept: contentType,
        "Timezone-Offset": LOCAL_TIME_ZONE,
      },
      ...config,
    },
  );
};

export const addDisclaimer = (doc: jsPDF) =>
  doc.text(
    "Confidential, Not for Distribution",
    PDF_SETTINGS.margin / 2,
    doc.internal.pageSize.getHeight() - 10,
  );

export const downloadPDF = async (
  canvas: HTMLCanvasElement,
  dataPath: AnyDataPath,
) => {
  let tableData: TableData | null = null;

  if (!isReportsDataPath(dataPath)) {
    // table just for analytics

    try {
      const response = await fetchExportData(ContentType.JSON, dataPath);

      if (response.data) {
        tableData = parseAnalyticsData(response.data, dataPath);
      }
    } catch {}
  }

  const doc = new jsPDF({
    format: PDF_SETTINGS.format,
    unit: PDF_SETTINGS.unit,
  });

  doc.setFontSize(PDF_SETTINGS.fontSize);
  doc.setTextColor(PDF_SETTINGS.textColor);
  doc.setFont("Gilroy", PDF_SETTINGS.fontStyle);
  doc.text(
    `Exported at ${format(new Date(), DATE_FORMAT.DAY)}`,
    PDF_SETTINGS.margin / 2,
    PDF_SETTINGS.margin / 2,
  );

  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const pageRation = pageHeight / pageWidth;
  const canvasWidthOnePage = canvas.width;
  const canvasHeightOnePage = canvas.width * pageRation;
  const disclaimerHeight = 15;
  const dateExportedHeight = 10;
  let position = 0;

  if (canvas) {
    for (let page = 0; page <= canvas.height / canvasHeightOnePage; page++) {
      const sY = canvasHeightOnePage * page;
      const heightToCut = Math.min(canvas.height - sY, canvasHeightOnePage);

      const newCanvas = document.createElement("canvas");

      newCanvas.setAttribute("width", canvasWidthOnePage.toString());
      newCanvas.setAttribute("height", canvasHeightOnePage.toString());
      const ctx = newCanvas.getContext("2d");

      ctx.fillStyle = "#ffffff";
      ctx.fillRect(0, 0, canvasWidthOnePage, canvasHeightOnePage);
      // details on this usage of this function:
      // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Using_images#Slicing
      ctx.drawImage(
        canvas,
        0,
        sY,
        canvasWidthOnePage,
        heightToCut,
        0,
        0,
        canvasWidthOnePage,
        heightToCut,
      );

      //! If we're on anything other than the first page,
      // add another page
      if (page > 0) {
        addDisclaimer(doc);
        doc.addPage();
      }
      //! now we declare that we're working on that page
      doc.setPage(page + 1);
      const imgData = newCanvas.toDataURL("image/jpeg", 0.5);
      const pdfHeightOfImage =
        (canvas.height - sY) / (canvas.width / pageWidth);
      const heightOfPastedImage =
        pageWidth * pageRation - PDF_SETTINGS.margin - disclaimerHeight;

      doc.addImage(
        imgData,
        "PNG",
        PDF_SETTINGS.margin / 2,
        page === 0
          ? PDF_SETTINGS.margin / 2 + dateExportedHeight
          : PDF_SETTINGS.margin / 2,
        pageWidth - PDF_SETTINGS.margin,
        page === 0
          ? heightOfPastedImage - dateExportedHeight
          : heightOfPastedImage,
      );
      position = pdfHeightOfImage;
    }
  }

  if (tableData) {
    position += PDF_SETTINGS.margin;

    autoTable(doc, {
      head: [tableData.headers],
      body: tableData.data,
      margin: {left: PDF_SETTINGS.margin / 2, right: PDF_SETTINGS.margin / 2},
      didDrawPage: function () {
        // Footer
        addDisclaimer(doc);
      },
      startY: position + 5,
    });
  }

  if (!tableData) {
    addDisclaimer(doc);
  }

  doc.save(createFilename(dataPath, FileExtension.PDF));
};

const saveFile = (filename: string, data: string, type: ContentType) =>
  saveAs(new Blob([data], {type}), filename);

export const downloadXLSX = async (dataPath: AnyDataPath) => {
  try {
    const {data} = await fetchExportData(ContentType.XLSX, dataPath, {
      responseType: "blob",
    });

    saveFile(
      createFilename(dataPath, FileExtension.XLSX),
      data,
      ContentType.XLSX,
    );
  } catch {
    Alert.error("Failed to download Excel.");
  }
};

export const downloadCSV = async (dataPath: AnyDataPath) => {
  try {
    const {data} = await fetchExportData(ContentType.CSV, dataPath);

    saveFile(
      createFilename(dataPath, FileExtension.CSV),
      data,
      ContentType.CSV,
    );
  } catch {
    Alert.error("Failed to download CSV.");
  }
};
