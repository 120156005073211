import {styled} from "@reside/ui";

export const FacilitySelectWrapper = styled.div<{
  hasSingleOrganization: boolean;
}>`
  .hidden-parent
    > .rc-tree-node-content-wrapper.rc-tree-node-content-wrapper-open {
    display: none;
  }
  .rc-tree-child-tree {
    padding-left: ${({hasSingleOrganization}) =>
      hasSingleOrganization && "0px"};
  }
`;
export const Wrapper = styled.div`
  .trigger {
    font-size: 23px;
  }

  .rc-tree {
    overflow-y: scroll;
  }
`;
