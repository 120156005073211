import {useState, useEffect} from "react";

type FileOrString = File | string | null | undefined;

/** Creates and manages object URLs for file previews, with automatic cleanup */
export const useObjectUrl = (value: FileOrString, defaultUrl?: string) => {
  const [previewUrl, setPreviewUrl] = useState<string>(defaultUrl || "");

  useEffect(() => {
    // Reset to default if no value provided
    if (!value) {
      setPreviewUrl(defaultUrl || "");
      return;
    }

    // Use URL directly if it's already a string, meaning it's a default image or a logo from theme
    if (typeof value === "string") {
      setPreviewUrl(value);
      return;
    }

    // Create new object URL for File objects
    const objectUrl = URL.createObjectURL(value);
    setPreviewUrl(objectUrl);

    // Cleanup function to prevent memory leaks
    return () => {
      if (objectUrl && value instanceof File) {
        URL.revokeObjectURL(objectUrl);
      }
      return;
    };
  }, [value, defaultUrl]);

  return previewUrl;
};
