import React from "react";
import {DataNode} from "rc-tree/lib/interface";
import {connect} from "react-redux";

import {ActiveFacilities} from "../../store/reporting/reporting.common";
import {AppState, select} from "../../store";
import {DropdownTreeSelect} from "../dropdown-tree-select";

import {prepareFacilitiesTreeForDisplay} from "./helpers";
import {Wrapper, FacilitySelectWrapper} from "./styles";

type Props = Readonly<
  {
    hasOnlyOne?: boolean;
    hideTrigger?: boolean;
    searchPlaceholder?: string;
    triggerLabel?: string;
    autoComplete?: string;
    withApplyFiltersButton?: boolean;
  } & StateProps &
    DispatchProps
>;

export const FacilityDropdownTreeSelectRenderer = ({
  activeFacilitiesTreeKeys,
  facilitiesTree,
  hasOnlyOne,
  hideTrigger = false,
  searchPlaceholder = "Search by organization or facility",
  setActiveFacilities,
  triggerLabel = "Facilities",
  autoComplete,
  withApplyFiltersButton = false,
}: Props) => (
  <Wrapper>
    <DropdownTreeSelect
      checkedKeys={activeFacilitiesTreeKeys}
      EmptySearch={() => <span>No matching organization or facility</span>}
      hideTrigger={hideTrigger}
      hasOnlyOne={hasOnlyOne}
      placement="bottom-start"
      searchPlaceholder={searchPlaceholder}
      treeData={facilitiesTree}
      triggerLabel={triggerLabel}
      onCheck={(checkedKeys, checkedLeafKeys) => {
        setActiveFacilities({
          checkedKeys,
          checkedLeafKeys,
        });
      }}
      autoComplete={autoComplete}
      withApplyFiltersButton={withApplyFiltersButton}
    />
  </Wrapper>
);

const Connected = ({
  activeFacilitiesTreeKeys,
  facilitiesTree,
  setActiveFacilities,
}: StateProps & DispatchProps) => {
  const {modifiedFacilityTree, hasSingleFacility, hasSingleOrganization} =
    prepareFacilitiesTreeForDisplay(facilitiesTree);

  return (
    modifiedFacilityTree?.length > 0 && (
      <FacilitySelectWrapper hasSingleOrganization={hasSingleOrganization}>
        <FacilityDropdownTreeSelectRenderer
          activeFacilitiesTreeKeys={activeFacilitiesTreeKeys}
          facilitiesTree={modifiedFacilityTree}
          hideTrigger={hasSingleFacility}
          setActiveFacilities={setActiveFacilities}
          triggerLabel={hasSingleFacility ? "Facility" : "Facilities"}
          withApplyFiltersButton
        />
      </FacilitySelectWrapper>
    )
  );
};

type StateProps = {
  activeFacilitiesTreeKeys: ReadonlyArray<string>;
  facilitiesTree: ReadonlyArray<DataNode>;
};

const mapStateToProps = (state: AppState) => ({
  facilitiesTree: select.adminSession.facilitiesTree(state),
  activeFacilitiesTreeKeys: select.adminSession.activeFacilitiesTreeKeys(state),
});

type DispatchProps = {
  setActiveFacilities: (data: ActiveFacilities) => void;
};

const mapDispatchToProps = (dispatch: any) => ({
  setActiveFacilities: (payload: ActiveFacilities) => {
    dispatch.adminSession.setActiveFacilities(payload);
  },
});

export const FacilityDropdownTreeSelect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Connected);
