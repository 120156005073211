import {InfoBar, styled, theme} from "@reside/ui";
import {hexToRgba} from "../../utils";
import {isHighContrastColor} from "../../../../utils/helpers/isHighContrastColor";

export const PreviewBlockWrapper = styled.div`
  overflow-y: auto;
  flex: 16;
  transform: scale(0.8);
  height: 78vh;
  button,
  #signature {
    pointer-events: none;
  }
`;

export const PreviewPageVerifyAnswersWrapper = styled.div<{color: string}>`
  #verify-answers {
    height: 100% !important;
    transform: scale(1);

    background: linear-gradient(
      ${({color}) => hexToRgba(color, 0.5)},
      rgba(255, 255, 255, 0.5)
    );
  }
  form {
    pointer-events: none;
  }
  form > button {
    background-color: ${({color}) => color} !important;
    border: 1px solid
      ${({theme, color}) => {
        const primary = color;

        if (isHighContrastColor(primary)) {
          return theme.color.textPrimary;
        }

        return primary;
      }};

    span {
      color: ${({theme, color}) => {
        const primary = color;

        if (isHighContrastColor(primary)) {
          return theme.color.textPrimary;
        }

        return theme.color.white;
      }} !important;
    }
  }

  footer > button > i {
    color: ${({color}) => {
      const primary = color;

      if (isHighContrastColor(primary)) {
        return theme.color.textPrimary;
      }

      return primary;
    }} !important;
    border-color: ${({color}) => {
      const primary = color;

      if (isHighContrastColor(primary)) {
        return theme.color.textPrimary;
      }

      return primary;
    }} !important;
  }
`;

export const PreviewPageAdmissionPreludeWrapper = styled.div<{
  color: string;
  backgroundDefault?: string;
}>`
  background: ${({backgroundDefault}) => backgroundDefault};
  height: 100%;
`;

export const PreviewPageAdmissionPreludeRadioGroupWrapper = styled.div<{
  color: string;
}>`
  pointer-events: none;
  width: 70%;
  margin: auto;
  max-width: 800px;
  background-color: ${theme.color.white};
  border: 1px solid ${theme.color.borderGray};
  border-radius: 8px;
  padding: 4px 20px 20px 20px;
  transform: scale(0.8);

  button {
    background-color: ${({color}) => color} !important;
    border: 1px solid
      ${({theme, color}) => {
        const primary = color;

        if (isHighContrastColor(primary)) {
          return theme.color.textPrimary;
        }

        return primary;
      }};

    span {
      color: ${({theme, color}) => {
        const primary = color;

        if (isHighContrastColor(primary)) {
          return theme.color.textPrimary;
        }

        return theme.color.white;
      }} !important;
    }
  }

  svg > circle {
    stroke: ${({color}) => color} !important;
  }
`;

export const PreviewPageFirstSlideWrapper = styled.div<{
  color: string;
  accentInfoColor: string;
  accentInfoTextColor: string;
  backgroundDefault?: string;
}>`
  padding-bottom: 20px;
  width: 100%;
  background: ${({backgroundDefault}) => backgroundDefault};
  #signature {
    border-color: ${({color}) => color};
    div {
      color: ${({color}) => color};
      path {
        stroke: ${({color}) => color};
      }
      path:first-child {
        fill: ${({color}) => color};
      }
    }
  }

  #signature {
    svg {
      fill: ${({color}) => color};
    }
    background-color: ${({color}) => color}10;
  }

  #checkbox-icon {
    border-color: ${({color}) => color};
    background-color: ${({color}) => color};
  }
  #checkbox-icon:checked {
    background-color: ${({color}) => color};
  }

  .icon-check {
    background-color: ${({color}) => color};
  }

  .info {
    background-color: ${({accentInfoColor}) => accentInfoColor}50 !important;
    color: ${({accentInfoTextColor}) => accentInfoTextColor} !important;
  }
`;

export const Flex = styled.div<{fullscreen: boolean}>`
  display: flex;
  flex-flow: row nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  align-content: stretch;
  align-self: stretch;
  flex-wrap: nowrap;
  padding: 0 10px;
`;

export const Navbar = styled.div<{fullscreen: boolean; color: string}>`
  width: 100%;
  height: 72px;
  color: ${({theme}) => theme.color.darkBlue100};
  background-color: ${({theme}) => theme.color.white};
  margin-bottom: 10px;
  #go-to-previous-slide-button,
  #go-to-next-slide-button,
  #pause-admission-button {
    border: 2px solid
      ${({theme, color}) => {
        const primary = color;

        if (isHighContrastColor(primary)) {
          return theme.color.textPrimary;
        }

        return primary;
      }} !important;

    i {
      color: ${({theme, color}) => {
        const primary = color;

        if (isHighContrastColor(primary)) {
          return theme.color.textPrimary;
        }

        return primary;
      }};
    }
  }
`;

export const InfoBarStyled = styled(InfoBar)``;
