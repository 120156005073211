import {CustomThemeModel} from "../../services/BrandingService";

export const hexToRgba = (hex: string, opacity: number) => {
  // Remove the hash if it exists
  hex = hex.replace("#", "");

  // Parse the hex values
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Return rgba string
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

// Helper function to sort themes by creation date to maintain consistent ordering
export const sortThemesByCreationDate = (themes: CustomThemeModel[]) => {
  return [...themes].sort(
    (a, b) => new Date(a.created).getTime() - new Date(b.created).getTime(),
  );
};
