import {useEffect} from "react";
import {useQuery} from "react-query";
import {BrandingService} from "../../../services/BrandingService";
import {ACTIVE_CUSTOM_THEME_KEY, DEFAULT_THEME} from "../constants";

export const useFetchActiveBrandingTheme = ({
  organizationIdByUser,
}: {
  organizationIdByUser: string;
}) => {
  const {data: activeTheme, isLoading} = useQuery(
    ["activeTheme", organizationIdByUser],
    async () => {
      const response = await BrandingService.getActiveTheme(
        organizationIdByUser,
      );
      return response.data;
    },
    {
      enabled: Boolean(organizationIdByUser),
    },
  );

  useEffect(() => {
    if (
      // Fetch completed
      activeTheme !== undefined
    ) {
      if (
        // No active theme - theme is default
        activeTheme === null
      ) {
        localStorage.setItem(ACTIVE_CUSTOM_THEME_KEY, DEFAULT_THEME.id); // Remove active custom theme so default theme is used
      } else {
        localStorage.setItem(
          ACTIVE_CUSTOM_THEME_KEY,
          JSON.stringify(activeTheme), // Store active custom theme
        );
      }
    }
  }, [activeTheme]);

  return {
    activeCustomBrandingTheme: activeTheme,
    isLoading,
  };
};
