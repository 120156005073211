import React from "react";
import {PageAdmissionVerifyAnswers} from "../../../page-admission-verify-answers/PageAdmissionVerifyAnswers";
import {PreviewPageVerifyAnswersWrapper} from "./styles";
import {PreviewPageVerifyAnswersProps} from "./types";
import {theme} from "@reside/ui";

/**
 * This component serves as a preview for customization. It is the first slide in admission process.
 *
 * This component is purely for preview purposes and does not include any
 * functional logic.
 *
 */
export const PreviewPageVerifyAnswers = ({
  logoUrl,
  color = theme.color.primary,
}: Pick<PreviewPageVerifyAnswersProps, "logoUrl" | "color">): JSX.Element => (
  <PreviewPageVerifyAnswersWrapper color={color}>
    <PageAdmissionVerifyAnswers
      logoutOnUnauthorized={false}
      logoUrl={logoUrl}
    />
  </PreviewPageVerifyAnswersWrapper>
);
