import {useQuery} from "react-query";

import {
  FeatureFlagsService,
} from "../../../services/FeatureFlagsService";

type Params = {
  facilityIds: ReadonlyArray<string>;
};

export const useFetchFeatureFlagsByFacilities = ({facilityIds}: Params) =>
  useQuery(
    ["useFetchFeatureFlagsByFacilities", facilityIds],
    async ({queryKey: [_, facilityIds]}) => {
      const {data} = await FeatureFlagsService.get_facility_feature_flags(
        facilityIds as ReadonlyArray<string>,
      );

      return data;
    },
  );
