import React, {useCallback} from "react";
import {Formik} from "formik";
import {RoleDto} from "@reside/reside-api-app";

import unflatten from "obj-unflatten";
import {validator} from "@reside/forms";
import {
  styled,
  FormGrid,
  FormSlide,
  FormCard,
  Button,
  TextField,
  StatesField,
  Box,
  Icon,
  CheckboxItem,
  ThinScrollbarArea,
} from "@reside/ui";

import {Space} from "../space";
import {AdminPageTitle} from "../admin-layout/AdminPageTitle";
import {AdminRoles} from "../admin-roles";
import {ButtonLink} from "../button-link";
import {domainNamesListToString, domainNamesStringToList} from "./helper";
import {AppState, select} from "../../store";
import {connect} from "react-redux";
import {
  CreateOrganizationRequest,
  UpdateOrganizationRequest,
} from "../../utils/types/organization";
import {ThemesGalery} from "../../pages/page-admin-organizations-branding/atoms/Themes/ThemesGalery";

const baseUrl = "/admin/organizations";

type CreateOrUpdateOrganization = CreateOrganizationRequest &
  UpdateOrganizationRequest;

const INITIAL_VALUES: CreateOrUpdateOrganization = {
  name: "",
  description: "",
  phone: "",
  email: "",
  domains: "",
  address: {
    street: "",
    number: "",
    zipcode: "",
    city: "",
    state: "",
  },
  deactivateUsersWithoutWhitelistEmail: true,
};

const VALIDATIONS = {
  name: "required|organization-name",
  phone: "required|phone",
  email: "email",
  domains: "required|domain-name-list|reside-domain",
  address: {
    street: "required",
    zipcode: "required|zip-code",
    city: "required",
    state: "required",
  },
};

type Props = Readonly<{
  onSubmit: (values: CreateOrUpdateOrganization) => void;
  initialValues?: CreateOrganizationRequest;
  onStatusChange?: () => Promise<void> | void;
  isResideAdmin: boolean;
  status?: string;
}>;

/**
 * @param onSubmit
 * @param initialValues
 * @param onStatusChange
 * @param isResideAdmin
 * @param status
 * @constructor
 */
const Renderer = ({
  onSubmit,
  initialValues,
  onStatusChange,
  isResideAdmin,
  status,
}: Props) => {
  const title = initialValues ? "Edit Organization" : "New Organization";

  const deactivateUsersCheckbox = !!initialValues;

  const allActiveDomainsPresent = (
    values: CreateOrUpdateOrganization,
  ): boolean => {
    if (initialValues.domains)
      return domainNamesStringToList(initialValues.domains).every(domainName =>
        domainNamesStringToList(values.domains).includes(domainName),
      );
    return true;
  };

  const removedActiveDomains = (values: CreateOrUpdateOrganization): string => {
    return domainNamesListToString(
      domainNamesStringToList(initialValues.domains).filter(
        domainName =>
          !domainNamesStringToList(values.domains).includes(domainName),
      ),
    );
  };

  const validate = (values: CreateOrUpdateOrganization) =>
    unflatten(validator.validateAll(values, VALIDATIONS));

  const StatusButtons = () => {
    if (!initialValues || !onStatusChange) {
      return null;
    }

    const title = status === "ACTIVE" ? "Deactivate" : "Activate";
    const color = status === "ACTIVE" ? "danger" : "success";

    return (
      <AdminRoles roles={[RoleDto.NameEnum.RESIDE_ADMIN]}>
        <Space>
          {status === "ACTIVE" && (
            <ButtonLink
              to={`${baseUrl}/${initialValues.id}/create-facility`}
              color="primary"
            >
              <AddIcon name="add" size={14} />
              New Facility
            </ButtonLink>
          )}
          {isResideAdmin && (
            <ButtonLink
              to={`${baseUrl}/${initialValues.id}/manage-users`}
              color="success"
            >
              Manage Users
            </ButtonLink>
          )}
          <Button onClick={onStatusChange} color={color} outline>
            {title}
          </Button>
        </Space>
      </AdminRoles>
    );
  };

  const setDeactivateUsersWithoutWhitelistEmail = useCallback(
    (
        field: string,
        value: boolean,
        func: (field: string, value: boolean) => void,
      ) =>
      () =>
        func(field, value),
    [],
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues ?? INITIAL_VALUES}
      onSubmit={onSubmit}
      validate={validate}
    >
      {({submitForm, setFieldValue, values}) => (
        <>
          <Header
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <div>
              <AdminPageTitle>{title}</AdminPageTitle>
            </div>
            <StatusButtons />
            <ActionButtons submitForm={submitForm} />
          </Header>
          <ThinScrollbarArea>
            <FormGrid>
              <FormSlide>
                <FormCard title="Organization">
                  <TextField label="Name" name="name" />
                  <TextField label="Description" name="description" />
                  <TextField label="Email Address" name="email" type="email" />
                  <TextField label="Phone" name="phone" format="us-phone" />
                  <TextField
                    label="Domains"
                    name="domains"
                    capitalize={false}
                  />
                  {deactivateUsersCheckbox &&
                    !allActiveDomainsPresent(values) && (
                      <CheckboxItem
                        label={`Deactivate users with email domains: ${removedActiveDomains(
                          values,
                        )}`}
                        name="deactivateUsersWithoutWhitelistEmail"
                        onChange={setDeactivateUsersWithoutWhitelistEmail(
                          "deactivateUsersWithoutWhitelistEmail",
                          !values.deactivateUsersWithoutWhitelistEmail,
                          setFieldValue,
                        )}
                        checked={values.deactivateUsersWithoutWhitelistEmail}
                      >
                        <p>
                          <strong style={{color: "red"}}>NB!</strong> When
                          selected, active/new users with email domains that are
                          not specified in the list will be deactivated.
                        </p>
                      </CheckboxItem>
                    )}
                </FormCard>

                <FormCard title="Address">
                  <TextField
                    label="Address (234 Main St.)"
                    name="address.street"
                  />
                  <TextField
                    label="Address line 2 (Apartment, suite, unit)"
                    name="address.number"
                  />
                  <TextField label="City" name="address.city" />
                  <StatesField label="State" name="address.state" />
                  <TextField
                    label="Zip Code"
                    name="address.zipcode"
                    format="zip-code"
                  />
                </FormCard>

                <AdminRoles
                  roles={[
                    RoleDto.NameEnum.RESIDE_ADMIN,
                    RoleDto.NameEnum.ORGANIZATION_ADMIN,
                  ]}
                >
                  {
                    // Show only when editing an organization
                    initialValues && (
                      <FormCard title="Resident Experience Branding">
                        <ThemesGalery organizationId={initialValues?.id} />
                      </FormCard>
                    )
                  }
                </AdminRoles>
              </FormSlide>
            </FormGrid>
          </ThinScrollbarArea>
        </>
      )}
    </Formik>
  );
};

const ActionButtons = ({submitForm}: {submitForm: () => any}) => (
  <Space>
    <ButtonLink to={baseUrl}>Cancel</ButtonLink>
    <Button color="success" onClick={submitForm}>
      Save
    </Button>
  </Space>
);

/**
 * @param state
 */
const mapState = (state: AppState) => ({
  isResideAdmin: select.adminSession.isResideAdmin(state),
});

export const OrganizationForm = connect(mapState)(Renderer);

const AddIcon = styled(Icon)`
  padding-right: 10px;
`;

const Header = styled(Box)`
  margin-bottom: 15px;
`;
