import React, {useCallback, useEffect} from "react";
import {toFormikValidationSchema} from "zod-formik-adapter";
import {PccFacilityMappingDto} from "@reside/reside-api-app";
import {Subtitle, Card} from "@reside/ui";
import {Formik} from "formik";
import {z} from "zod";

import {INITIAL_VALUES_FOR_PCC_DISABLED_FACILITY} from "./constants";
import {BackdropSpinner} from "../../../../../atoms/spinner";
import {FacilityPccMappingFormValues} from "./types";
import {PccFeatureFlags} from "./PccFeatureFlags";
import {hasPccMappingData} from "./helpers";
import {FormButtons} from "./FormButtons";
import {FormFields} from "./FormFields";
import {
  usePccFacilityMappingQuery,
  usePccFacilityMappingMutation,
  useFacilityPccFeatureFlags,
  useFacilitySettingsParams,
} from "../../../hooks";

/**
 * FacilityPccMappingForm component handles the form logic and UI for managing
 * PointClickCare (PCC) facility connection settings.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {boolean} props.isPccEnabled - Flag indicating if PCC is enabled for the facility.
 * @returns {JSX.Element} JSX element representing the PCC facility mapping form.
 *
 * @see {@link usePccFacilityMappingQuery} for fetching PCC facility mapping data.
 * @see {@link usePccFacilityMappingMutation} for updating the PCC facility mapping.
 *
 */
export const FacilityPccMappingForm = ({
  isPccEnabled,
}: {
  isPccEnabled: boolean;
}) => {
  const {facilityId} = useFacilitySettingsParams(); // Get facility ID from route parameters

  const {activeFeatureFlagsOfFacility} = useFacilityPccFeatureFlags();

  // State to hold the initial form values until the data is fetched
  const [initialValues, setInitialValues] = React.useState({
    ...INITIAL_VALUES_FOR_PCC_DISABLED_FACILITY,
    pccFeatureFlags: [],
  });

  // Fetch current PCC facility mapping data
  const {
    data: pccFacilityMapping,
    isFetching: isFetchingPccFacilityMapping,
    refetch: refetchPccFacilityMapping,
  } = usePccFacilityMappingQuery({
    facilityId,
  });

  // Mutation hook for updating the PCC facility mapping
  const {
    mutate: updatePccFacilityMapping,
    isLoading: isUpdatingPccFacilityMapping,
    isSuccess: isUpdatedPccFacilityMapping,
  } = usePccFacilityMappingMutation();

  // Handle form submission
  const onFormikSubmit = useCallback(
    (pccFacilityMapping: PccFacilityMappingDto) =>
      updatePccFacilityMapping({facilityId, pccFacilityMapping}),
    [updatePccFacilityMapping, facilityId],
  );

  // Re-set initial form values when data is fetched to avoid stale data
  useEffect(() => {
    const newValues = hasPccMappingData(pccFacilityMapping)
      ? {
          ...pccFacilityMapping,
          pccFacilityId: pccFacilityMapping.pccFacilityId.toString(),
          pccFeatureFlags: activeFeatureFlagsOfFacility.filter(featureFlag =>
            featureFlag.includes("PCC"),
          ) as string[],
        } // Set initial values to value of facilty mapping data
      : {
          pccFacilityId: "" as unknown as number,
          pccOrganizationId: "",
          pccFeatureFlags: [] as string[],
        }; // Re-set values to empty when mapping is deleted so that button is disabled again

    // Only update state if values have changed
    if (JSON.stringify(newValues) !== JSON.stringify(initialValues)) {
      setInitialValues(newValues as FacilityPccMappingFormValues);
    }
  }, [pccFacilityMapping, activeFeatureFlagsOfFacility, initialValues]);

  // Refetch data if the update is successful
  useEffect(() => {
    if (isUpdatedPccFacilityMapping) {
      refetchPccFacilityMapping();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdatedPccFacilityMapping]);

  // Create a schema for the form validationsta i
  const Schema = z
    .object({
      pccOrganizationId: z.string().optional(),
      pccFacilityId: z.string().optional(),
    })
    .superRefine((data, ctx) => {
      const bothFilled = data.pccOrganizationId && data.pccFacilityId; // Both fields filled
      const bothEmpty = !data.pccOrganizationId && !data.pccFacilityId; // Both fields empty

      if (!bothFilled && !bothEmpty) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Both fields must be filled or both must be empty.",
          path: ["pccOrganizationId"], // Add error to `pccOrganizationId`
        });

        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Both fields must be filled or both must be empty.",
          path: ["pccFacilityId"], // Add error to `pccFacilityId`
        });
      }
    });

  return (
    <BackdropSpinner active={isFetchingPccFacilityMapping}>
      <Formik<FacilityPccMappingFormValues>
        enableReinitialize
        initialValues={initialValues}
        onSubmit={values => onFormikSubmit(values)}
        validationSchema={toFormikValidationSchema(Schema)}
      >
        {({isValid}) => (
          <Card>
            <Subtitle style={{marginBottom: "20px"}}>
              PointClickCare Connection Settings
            </Subtitle>

            <FormFields isPccEnabled={isPccEnabled} />

            <FormButtons
              isFormValid={isValid}
              isPccFacilityMappingUpdating={isUpdatingPccFacilityMapping}
            />

            <PccFeatureFlags
              isPccEnabled={isPccEnabled}
              facilityId={facilityId}
              hasPccMappingData={Boolean(pccFacilityMapping?.pccOrganizationId)}
              isPccFacilityMappingUpdating={isUpdatingPccFacilityMapping}
            />
          </Card>
        )}
      </Formik>
    </BackdropSpinner>
  );
};
