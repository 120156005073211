import {ApiResponse} from "apisauce";
import {http} from "./api/restApiClient";
import {Page} from "./api/pagination";

export type BrandingThemeColors = {
  primary: string;
  backgroundDefault: string;
  backgroundLight: string;
  fillSuccess: string;
  textSuccess: string;
  fillInfo: string;
  textInfo: string;
  textPrimary: string;
};

export type CustomThemeModel = {
  id: string;
  organization_id: string;
  theme_name: string;
  logo: string | File;
  logo_filename: string;
  logo_type: string;
  logo_size: number;
  colors: BrandingThemeColors;
  active: boolean;
  created: string;
  created_by: string;
};

export type CreateThemeRequest = Pick<
  CustomThemeModel,
  "theme_name" | "logo" | "colors"
>;

/** Create a FormData object from a CreateThemeRequest to handle multipart/form-data requests which contain files. */
const getFormData = (data: CreateThemeRequest) => {
  const formData = new FormData();
  formData.append("theme_name", data.theme_name);
  if (data.logo instanceof File) {
    formData.append("logo", data.logo);
  }
  formData.append("colors", JSON.stringify(data.colors));
  return formData;
};

export const BrandingService = {
  // Custom Branding Theming
  createTheme: (
    organizationId: string,
    data: CreateThemeRequest,
  ): Promise<ApiResponse<CustomThemeModel>> => {
    const formData = getFormData(data);

    return http.post(`/organizations/${organizationId}/themes`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  updateTheme: (
    organizationId: string,
    themeId: string,
    data: CreateThemeRequest,
  ) => {
    const formData = getFormData(data);

    return http.post(
      `/organizations/${organizationId}/themes/${themeId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
  },

  getTheme: (
    organizationId: string,
    themeId: string,
  ): Promise<ApiResponse<CustomThemeModel>> =>
    http.get(`/organizations/${organizationId}/themes/${themeId}`),

  getActiveTheme: (
    organizationId: string,
  ): Promise<ApiResponse<CustomThemeModel>> =>
    http.get(`/organizations/${organizationId}/themes/active`),

  getThemes: (
    organizationId: string,
    params: {query?: string},
  ): Promise<ApiResponse<Page<CustomThemeModel>>> =>
    http.get(`/organizations/${organizationId}/themes`, params),

  /** Get the active theme for a resident on successful verification in case he opened an email admission link. Endpoint is extended to accept ADMISSION token as valid authorization medium.
   *
   * https://vscode.dev/github/Reside-Admissions/core/blob/DEV-2208-customize-resident-experience-with-branding/reside-fe/reside-app/src/utils/token.ts#L4
   *
   */
  getActiveThemeWithAdmissionToken: (
    token: string,
  ): Promise<ApiResponse<Page<CustomThemeModel>>> => {
    return http.get("/admissions/theme", undefined, {
      params: {
        token,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  activateTheme: (organizationId: string, themeId: string) =>
    http.put(`/organizations/${organizationId}/themes/${themeId}/activate`),

  activateDefaultTheme: (organizationId: string) =>
    http.put(`/organizations/${organizationId}/themes/default`),

  deleteTheme: (organizationId: string, themeId: string) =>
    http.delete(`/organizations/${organizationId}/themes/${themeId}`),
};
