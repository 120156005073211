import React, {useCallback, useEffect} from "react";
import {connect} from "react-redux";
import Alert from "react-s-alert";
import {Values} from "@reside/forms/dist/blocks";
import {Paragraph} from "@reside/ui";

import {Dispatch, select, store} from "../../store";
import {bindAdmissionActionsForInstance} from "../../models/AdmissionsModel";
import {Spinner} from "../../atoms/spinner";
import {Draft} from "../../atoms/admission-explorer/model";
import {Banner} from "../../atoms/banner";
import {ResidentExperiencePreludeForm} from "./atoms/resident-experience-prelude-form";
import {AdmissionTemplateError} from "../../models/admission-session/AdmissionSessionModel";
import {useTemplateVersionQuery} from "../../hooks/useTemplateVersionQuery";
import {usePreludeTemplateQuery} from "../../hooks/useTemplateQuery";
import {UserIdentity} from "./helper";
import {useFetchActiveThemeWithAdmissionToken} from "../page-admin-organizations-branding/hooks/useFetchActiveThemeWithAdmissionToken";

type StateProps = Readonly<{
  draft: Draft;
  loadingStartSession: boolean;
  token: string;
}>;

type DispatchProps = Readonly<{
  setToken: (token: string) => void;
  startSession: (payload: any) => Promise<void>;
  findAsync: (fetchTemplate: boolean) => Promise<void>;
}>;

type Props = StateProps & DispatchProps;

const PageResidentExperiencePreludeRenderer = ({
  draft,
  loadingStartSession,
  findAsync,
  startSession,
  token,
}: Props) => {
  useEffect(() => {
    if (!draft) {
      findAsync(false);
    }
  }, [draft, findAsync]);

  const facilityId = draft?.instance?.facilityId;

  return draft && facilityId ? (
    <PageBody
      draft={draft}
      loadingStartSession={loadingStartSession}
      facilityId={facilityId}
      startSession={startSession}
      token={token}
    />
  ) : (
    <Spinner />
  );
};

const PageBody = ({
  draft,
  loadingStartSession,
  facilityId,
  startSession,
  token,
}: Readonly<{facilityId: string; token: string}> &
  Pick<Props, "draft" | "loadingStartSession" | "startSession">) => {
  const {mutate: fetchActiveTheme, isLoading: isLoadingActiveTheme} =
    useFetchActiveThemeWithAdmissionToken();
  const {data: {version} = {}, isFetching: isFetchingVersion} =
    useTemplateVersionQuery({facilityId});
  const {data: template, isLoading: isLoadingTemplate} =
    usePreludeTemplateQuery({
      versionId: version?.id,
    });

  useEffect(() => {
    const shouldFetchTheme =
      token && sessionStorage.getItem("theme-fetched") !== "true"; // Only fetch theme if it hasn't been fetched yet to prevent infinite re-renders

    if (shouldFetchTheme) {
      fetchActiveTheme({token});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const handleSubmit = useCallback(
    async (values: Values) => {
      try {
        await startSession(values);
      } catch (error) {
        if (error instanceof AdmissionTemplateError) {
          Alert.error(error.message);
        } else {
          Alert.error(
            "Something went wrong while saving the 'Tell Us Who You Are'. Please try again.",
          );
        }
      }
    },
    [startSession],
  );

  return isLoadingTemplate ||
    isFetchingVersion ||
    loadingStartSession ||
    isLoadingActiveTheme ? (
    <Spinner />
  ) : template ? (
    <ResidentExperiencePreludeForm
      initialValues={{}}
      template={template}
      onSubmit={handleSubmit}
    />
  ) : (
    <Banner title="Template not found">
      <Paragraph>Facility is missing the Prelude JSON template.</Paragraph>
    </Banner>
  );
};

const mapStateToProps = (state: any) => {
  const {admissionId} = select((models: any) => ({
    admissionId: models.admissionSession.getTokenSub,
  }))(state);

  return {
    token: select.admissionSession.getToken(state),
    draft: select.admissions.prelude({admissionId})(state),
    loadingStartSession: state.loading.effects.admissionSession.startSession,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const {admissionId} = select((models: any) => ({
    admissionId: models.admissionSession.getTokenSub,
  }))(store.getState());

  return {
    setToken: dispatch.admissionSession.setToken,
    startSession: (payload: UserIdentity) =>
      dispatch.admissionSession.startSession({
        admissionId,
        payload,
      }),
    findAsync: bindAdmissionActionsForInstance(admissionId)(dispatch).findAsync,
  };
};

export const PageAdmissionPrelude = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageResidentExperiencePreludeRenderer);
