import {
  H1,
  H2,
  Box,
  Button,
  Paragraph,
  TextField,
  Icon,
  theme,
  getCustomBrandingLogo,
} from "@reside/ui";
import {useMutation, MutationStatus} from "react-query";
import {Formik, Form} from "formik";
import {validator} from "@reside/forms/dist/validator";
import Alert from "react-s-alert";
import {EmailAdmissionEntryLinkDto} from "@reside/reside-api-admission";

import {AdmissionEntryService} from "../../services/AdmissionEntryService";
import {AutoLogoutInfoBar} from "../../atoms/auto-logout";
import {Center, Description, SubmitButton} from "./style";
import {FullscreenBackground} from "../../atoms/fullscreen-background";
import {CustomLogo} from "../../atoms/custom-logo/CustomLogo";

type PageWelcomeRendererProps = Readonly<{
  status: MutationStatus;
  onSubmit: (values: EmailAdmissionEntryLinkDto) => void;
  onReset: () => void;
  logoUrl?: string;
}>;

export const PageWelcomeRenderer = ({
  status,
  onSubmit,
  onReset,
  logoUrl = "/assets/reside_logo_extended.svg",
}: PageWelcomeRendererProps) => {
  const displayLogoUrl = getCustomBrandingLogo(logoUrl);

  return (
    <FullscreenBackground backgroundUrl="/assets/getStartedBg.webp">
      <div
        style={{
          width: "80%",
          position: "absolute",
          top: "46%",
          left: "50%",
          transform: "translate(-50%, -50%) scale(0.9)",
        }}
      >
        <Box width="100%" textAlign="center">
          <CustomLogo
            style={{
              maxWidth: "280px",
              maxHeight: "180px",
            }}
            alt="Company Logo"
            src={displayLogoUrl}
          />
          <H1
            style={{
              marginTop: "80px",
            }}
            fontSize={[6, 7]}
            lineHeight="70px"
          >
            Welcome to the Reside Resident Experience
          </H1>
        </Box>
        <Center>
          {status === "success" ? (
            <Box textAlign="center">
              <Icon name="email" size={128} color={theme.color.mint100} />
              <H2>Check your inbox!</H2>
              <Paragraph fontSize={4}>
                Click the link in your email to enter your admission! It may
                take some time to receive an email. In case you didn&apos;t
                receive the email please contact your facility representative.
              </Paragraph>
              <Button color="success" onClick={onReset}>
                Ok
              </Button>
            </Box>
          ) : (
            <Formik<EmailAdmissionEntryLinkDto>
              onSubmit={onSubmit}
              initialValues={{email: ""}}
              validate={values =>
                validator.validateAll(
                  values,
                  {
                    email: ["required", "email"],
                  },
                  {
                    required: "Please fill in your email.",
                    email: "Sorry, but that email is invalid.",
                  },
                )
              }
            >
              {() => (
                <Form>
                  <Description>
                    <H2>Login with your email</H2>
                    <Paragraph fontSize={4}>
                      To start admission, please enter your email address.
                      We&apos;ll send you a link for password-free login.
                    </Paragraph>
                  </Description>
                  <TextField
                    disabled={status === "loading"}
                    type="email"
                    name="email"
                    label="E-mail Address"
                    placeholder="E-mail Address (e.g. example@reside.com)"
                  />
                  <SubmitButton
                    size="large"
                    type="submit"
                    testId="sendLoginLinkButton"
                    color="primary"
                    disabled={status === "loading"}
                  >
                    {status === "loading" ? "Sending Link..." : "Send Link"}
                  </SubmitButton>
                </Form>
              )}
            </Formik>
          )}
        </Center>
      </div>
    </FullscreenBackground>
  );
};

export const PageWelcome = () => {
  const {mutate, status, reset} = useMutation(
    AdmissionEntryService.emailLoginLink,
    {
      onError: async (response: any) => {
        const error = await response.json();
        Alert.error(
          error?.status === 404
            ? "The email address you have entered doesn't match any account, please contact your Admission Representative."
            : "Could not send email at this time. Please, try again later.",
        );
      },
    },
  );

  return (
    <>
      <AutoLogoutInfoBar />
      <PageWelcomeRenderer status={status} onSubmit={mutate} onReset={reset} />
    </>
  );
};
