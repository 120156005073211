import {theme} from "@reside/ui";
import {ReactComponent as BaloonsSvg} from "./baloons.svg";

export const Baloons = () => {
  return (
    <svg
      style={
        {
          "--primary-color": theme.color.primary,
          "--secondary-color": theme.color.white,
          "--accent-color": theme.color.fillInfo,
        } as React.CSSProperties
      }
    >
      <BaloonsSvg />
    </svg>
  );
};
