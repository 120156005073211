import {styled, FlexColumn, theme} from "@reside/ui";
import Div100vh from "react-div-100vh";

import {LayoutContent} from "../../atoms/layout";
import {hexToRgba} from "../page-admin-organizations-branding/utils";
import {isHighContrastColor} from "../../utils/helpers/isHighContrastColor";

export const Layout = styled(Div100vh)`
  overflow-y: auto;
  &::after {
    content: "";
    background: linear-gradient(
        ${hexToRgba(theme.color.primary, 0.5)},
        rgba(255, 255, 255, 0.5)
      ),
      url("/assets/getStartedBg.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    opacity: 0.4;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }

  footer > button > i {
    color: ${({theme}) => {
      const primary = theme.color.primary;

      if (isHighContrastColor(primary)) {
        return theme.color.textPrimary;
      }

      return primary;
    }};
    border-color: ${({theme}) => {
      const primary = theme.color.primary;

      if (isHighContrastColor(primary)) {
        return theme.color.textPrimary;
      }

      return primary;
    }};
  }
`;

export const LayoutContentColumn = styled(LayoutContent)`
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
`;

export const Body = styled(FlexColumn)`
  @media (min-width: ${({theme}) => theme.breakpoint.lg}px) {
    flex-direction: row;
`;
