import {useMutation, useQueryClient} from "react-query";
import {useParams} from "react-router";
import Alert from "react-s-alert";
import {BrandingService} from "../../../services/BrandingService";

export const useActivateDefaultTheme = () => {
  const {organizationId} = useParams<{organizationId: string}>();
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      const response = await BrandingService.activateDefaultTheme(
        organizationId,
      );
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({queryKey: ["themes"]});
        Alert.success("Theme activated successfully");
      },
      onError: () => {
        Alert.error("Failed to activate theme");
      },
    },
  );
};
