import {FacilityFeatureFlagsDto} from "@reside/reside-api-app";
import {
  useFetchAllFacilityFeatureFlags,
  useFetchFeatureFlagsByFacilities,
} from "../../page-admin-feature-flags/hooks";
import {useFacilitySettingsParams} from "./useFacilitySettingsParams";

export type PCCFeatureFlags = FacilityFeatureFlagsDto.FeatureFlagsEnum;

type FacilityPCCFeatureFlagsChekbox = {
  facilityPccFeatureFlags: {
    label: string;
    value: string;
  }[];
  activeFeatureFlagsOfFacility: readonly PCCFeatureFlags[];
  refetchActiveFeatureFlags: () => void;
};

/**
 * Hook that fetches and returns all PCC feature flags for a given facility, with each flag indicating whether it is enabled or not.
 * @returns An array of objects, where each object contains the name of a PCC feature flag and a boolean indicating whether it is enabled.
 */

export const useFacilityPccFeatureFlags =
  (): FacilityPCCFeatureFlagsChekbox => {
    const {facilityId} = useFacilitySettingsParams(); // Get current facility ID from route parameters

    const {data: allFeatureFlags} = useFetchAllFacilityFeatureFlags(); // Fetch all feature flags
    const {data, refetch: refetchActiveFeatureFlags} =
      useFetchFeatureFlagsByFacilities({
        facilityIds: [facilityId],
      }); // Fetch active feature flags for current facility

    const activeFeatureFlagsOfFacility =
      data?.[0].facilities[0].featureFlags ?? [];

    const facilityPccFeatureFlags = allFeatureFlags
      ?.filter((featureFlag: PCCFeatureFlags) => featureFlag.includes("PCC")) // Filter out non-PCC feature flags
      .map((featureFlag: PCCFeatureFlags) => ({
        label: featureFlag,
        value: featureFlag,
      })); // Create an array of objects, where each object contains the name of a PCC feature flag and a boolean indicating whether it is enabled

    return {
      facilityPccFeatureFlags,
      activeFeatureFlagsOfFacility,
      refetchActiveFeatureFlags,
    };
  };
