import React from "react";
import {H2} from "@reside/ui";
import {Formik} from "formik";
import {validator} from "@reside/forms";

import {DocumentTitle} from "../../atoms/document-title";
import {AdminNavigationHeader} from "../../atoms/admin-navigation-header";
import {Copyright} from "../../atoms/copyright";
import {
  BrandingPageContainer,
  CustomizationBlocksWrapper,
  CustomizeResidentExperienceContainer,
  PreviewContainer,
  ActionsHeader,
} from "./styles";
import {UploadLogo} from "./atoms/UploadLogo";
import {Colors} from "./atoms/Colors/Colors";
import {
  BRANDING_COLORS,
  DEFAULT_THEME_INDEX,
  MAX_LOGO_SIZE_KB,
} from "./constants";
import {Preview} from "./atoms/Preview/Preview";
import {BrandingActions} from "./atoms/BrandingActions";
import {useFetchSingleTheme} from "./hooks/useFetchSingleTheme";
import {Spinner} from "../../atoms/spinner";
import {ThemeName} from "./atoms/ThemeName";

export const PageAdminOrganizationsBranding = (): JSX.Element => {
  const {data: theme, isFetching} = useFetchSingleTheme();

  // Find matching color index if theme exists
  const initialColorIndex = theme
    ? BRANDING_COLORS.findIndex(color => color.primary === theme.colors.primary)
    : DEFAULT_THEME_INDEX;

  const initialLogo = theme?.logo || null;

  if (isFetching) {
    return <Spinner />;
  }

  return (
    <>
      <DocumentTitle title="Reside - Admin - Branding" />
      <AdminNavigationHeader />
      <BrandingPageContainer>
        <Formik
          initialValues={{
            logo: initialLogo,
            colors: initialColorIndex.toString(), // Use found index or default to 0 - means default color palette
            theme_name: theme?.theme_name || "",
          }}
          validate={values => {
            const errors = validator.validateAll(
              values,
              {
                theme_name: ["required", "max:24"],
              },
              {
                required: "Please enter a theme name.",
                max: "The theme name must not exceed 24 characters.",
              },
            );

            if (values.logo instanceof File) {
              const maxSize = MAX_LOGO_SIZE_KB * 1024;
              if (values.logo.size > maxSize) {
                errors.logo = `The logo file size must not exceed ${MAX_LOGO_SIZE_KB}KB.`;
              }
            }

            return errors;
          }}
          onSubmit={() => {}}
        >
          {({dirty}) => (
            <>
              <CustomizeResidentExperienceContainer>
                <H2>Customize resident experience</H2>
                <CustomizationBlocksWrapper>
                  <ThemeName />
                  <UploadLogo />
                  <Colors />
                </CustomizationBlocksWrapper>
              </CustomizeResidentExperienceContainer>

              <PreviewContainer>
                <ActionsHeader>
                  <H2>Preview</H2>
                  <BrandingActions dirty={dirty} />
                </ActionsHeader>
                <Preview />
              </PreviewContainer>
            </>
          )}
        </Formik>
      </BrandingPageContainer>

      <Copyright />
    </>
  );
};
