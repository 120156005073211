import React from "react";
import {
  Checkbox,
  H2,
  ProgressBar,
  SignatureField,
  Text,
  theme,
} from "@reside/ui";
import {useFormikContext} from "formik";
import {BRANDING_COLORS} from "../../constants";
import {NavigationButtonsRenderer} from "../../../../atoms/admission-explorer/navbar-top/atoms/navigation-buttons";
import {TextSizeControl} from "../../../../atoms/admission-explorer/text-size-control";
import {HelpControlTrigger} from "../../../../atoms/admission-explorer/help-control";
import {PreviewPageProps} from "./types";
import {
  Flex,
  InfoBarStyled,
  Navbar,
  PreviewPageFirstSlideWrapper,
} from "./styles";
import {CustomLogo} from "../../../../atoms/custom-logo/CustomLogo";

/**
 * This component serves as a preview for customization. It is the first slide in admission process.
 *
 * This component is purely for preview purposes and does not include any
 * functional logic.
 *
 */

export const PreviewPageFirstSlide = ({
  logoUrl,
  color = theme.color.primary,
  backgroundDefault = theme.color.backgroundDefault,
}: Pick<PreviewPageProps, "logoUrl" | "color"> & {
  backgroundDefault?: string;
}): JSX.Element => {
  const {values} = useFormikContext<{colors: string}>();

  return (
    <PreviewPageFirstSlideWrapper
      color={color}
      backgroundDefault={backgroundDefault}
      accentInfoColor={BRANDING_COLORS[+values.colors].fillInfo}
      accentInfoTextColor={BRANDING_COLORS[+values.colors].textInfo}
    >
      <Navbar fullscreen={false} color={color}>
        <Flex fullscreen={false}>
          <NavigationButtonsRenderer
            onNext={() => {}}
            onPrev={() => {}}
            onPause={() => {}}
            nextDisabled={false}
            prevDisabled={false}
            pauseDisabled={false}
          />

          <Flex fullscreen={false}>
            <TextSizeControl
              darkBackground={false}
              textSize={1}
              onChange={() => {}}
            />

            <HelpControlTrigger
              active={false}
              darkBackground={false}
              disabled={false}
              onClick={() => {}}
            />
          </Flex>
        </Flex>
        <ProgressBar
          progress={10}
          width={"100%" as unknown as number}
          progressColor={() => color}
        />
      </Navbar>

      <InfoBarStyled accent="info" icon="info" title="Fill the form" />

      <CustomLogo
        src={logoUrl}
        alt="logo"
        style={{margin: "32px 32px 0px 32px"}}
      />

      <H2 padding="0 32px 24px 32px">Let&apos;s Get Started</H2>

      <Text as="p" fontSize={3} padding="0px 32px 0px 32px">
        This electronic admissions system will educate you and guide you through
        the information that our Facility needs in order to make your stay
        exemplary. The following slides will also inform you of your rights and
        answer questions such as how insurance works and what your stay at our
        Facility will entail. Reside exists to make your admission as simple as
        possible.
      </Text>

      <Text as="p" fontSize={3} padding="32px 32px 16px 32px">
        Check the box below to continue to the next slide.
      </Text>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "16px",
          padding: "0px 32px 32px 32px",
        }}
      >
        <Checkbox disabled={false} checked />
        <Text as="p" fontSize={3}>
          Your privacy is very important to us. Our <span>Terms of Use</span>,{" "}
          <span>Privacy Policy</span> and{" "}
          <span>HIPAA Notice of Privacy Practices</span> set forth the terms
          which apply to your use of our <strong>Reside Admissions</strong>{" "}
          application as part of the admissions process at this Facility. Please
          read these terms carefully. Your use of the application constitutes
          your consent to these Terms of Use. As privacy laws and practices
          evolve, we may amend the Terms of Use and Privacy Policy from time to
          time.
        </Text>
      </div>

      <Text as="p" fontSize={3} padding="0px 32px 16px 32px">
        Check the box below to continue to the next slide.
      </Text>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "16px",
          padding: "0px 32px 32px 32px",
        }}
      >
        <Checkbox disabled={false} checked />
        <Text as="p" fontSize={3}>
          <strong>
            By checking this box, you consent to your signature being applied
            electronically to the following Agreement wherever you click the
            “Accept” button in the Agreement, or the subpart of the following
            Agreement to which the signature relates, and, when you click on the
            “Accept” button in the following Agreement, that you acknowledge
            that you have read, understood and signed the Agreement, or the
            subpart of this Agreement to which the signature relates, and any
            related agreement referenced and incorporated therein.
          </strong>
        </Text>
      </div>

      <Text as="p" fontSize={3} padding="0px 32px 16px 32px">
        <strong>Print Name: </strong>John Doe
      </Text>

      <SignatureField
        name="signature"
        label="Signature of Resident/Authorized Representative:"
      />
    </PreviewPageFirstSlideWrapper>
  );
};
