var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { isUrl } from "../../../urlUtils";
import { styled } from "../../../theme";
import { Button } from "../../../button";
import { Text } from "../../../text";
import { FlexColumn } from "../../../box";
import { SUPPORTED_MEDIA_TYPE_IMAGES } from "../../snapshot-control-field/utils";
import { FileInput } from "../file-input";
import { ReactComponent as CameraPlaceholder } from "./assets/placeholder-camera.svg";
export var SnapshotControl = function (_a) {
    var name = _a.name, image = _a.image, disabled = _a.disabled, textSize = _a.textSize, onChange = _a.onChange, transformUrl = _a.transformUrl, onClear = _a.onClear;
    return (React.createElement(FlexStart, { style: { fontSize: "".concat(textSize, "rem") } },
        React.createElement(ClickDelegate, { disabled: disabled },
            React.createElement(ImagePreviewContainer, { disabled: disabled, "data-test-id": "".concat(name, "-preview") },
                image ? (React.createElement(PaddedImage, { src: isUrl(image) ? transformUrl(image) : image, alt: "Camera Preview" })) : (React.createElement(EmptyMessage, null,
                    React.createElement(CameraGlyph, { className: "image-placeholder-camera" },
                        React.createElement(CameraPlaceholder, null)),
                    React.createElement(Text, { fontSize: 4 }, "Tap here to add photo"))),
                React.createElement(FileInput, { value: "", name: name, multiple: false, acceptedMediaTypes: SUPPORTED_MEDIA_TYPE_IMAGES, disabled: disabled, onChange: onChange }))),
        image && (React.createElement(ClearButton, { outline: true, color: "danger", size: "small", icon: "close", onClick: onClear, disabled: disabled }, "Remove"))));
};
SnapshotControl.defaultProps = {
    textSize: 1,
    onChange: function () { },
    onClear: function () { },
    transformUrl: function (url) { return url; },
};
var ClickDelegate = styled.label(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  cursor: ", ";\n  max-width: Max(30rem, 30em);\n  width: 100%;\n"], ["\n  cursor: ", ";\n  max-width: Max(30rem, 30em);\n  width: 100%;\n"])), function (_a) {
    var disabled = _a.disabled;
    return (disabled ? "not-allowed" : "pointer");
});
var ClearButton = styled(Button)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-left: 25px;\n\n  &:hover {\n    transition-delay: 100ms;\n  }\n\n  ", "\n\n  ", "\n\n  &[type=\"button\"] {\n    font-size: 0.6875em;\n    padding-block: 0.45em;\n    padding-inline: 0.625em 1em;\n  }\n\n  .icon.icon {\n    position: initial;\n    display: grid;\n    font-size: 1em;\n    margin-right: 0.5em;\n  }\n\n  span[style*=\"margin: 0px auto;\"] {\n    padding-top: 0.125em;\n  }\n"], ["\n  margin-left: 25px;\n\n  &:hover {\n    transition-delay: 100ms;\n  }\n\n  ", "\n\n  ", "\n\n  &[type=\"button\"] {\n    font-size: 0.6875em;\n    padding-block: 0.45em;\n    padding-inline: 0.625em 1em;\n  }\n\n  .icon.icon {\n    position: initial;\n    display: grid;\n    font-size: 1em;\n    margin-right: 0.5em;\n  }\n\n  span[style*=\"margin: 0px auto;\"] {\n    padding-top: 0.125em;\n  }\n"])), function (_a) {
    var disabled = _a.disabled, theme = _a.theme;
    return disabled &&
        "\n    color: ".concat(theme.color.darkBlue60, ";\n    border-color: ").concat(theme.color.darkBlue60, ";\n  ");
}, function (_a) {
    var hidden = _a.hidden;
    return hidden &&
        "\n    visibility: hidden;\n  ";
});
var FlexStart = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n  margin-top: 0.625em;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n  margin-top: 0.625em;\n"])));
var ImagePreviewContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  border-radius: 8px;\n  color: ", ";\n  border: 1px solid ", ";\n  background-color: rgba(43, 183, 217, 0.05);\n  ", "\n"], ["\n  display: flex;\n  border-radius: 8px;\n  color: ", ";\n  border: 1px solid ", ";\n  background-color: rgba(43, 183, 217, 0.05);\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.primary;
}, function (_a) {
    var disabled = _a.disabled;
    return disabled &&
        "\n    opacity: 0.6;\n    filter: saturate(25%);\n";
});
var EmptyMessage = styled(FlexColumn)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  padding: 1.25em 0.625em;\n  user-select: none;\n\n  @media (min-width: ", "px) {\n    height: Max(18.75rem, 18.75em);\n  }\n"], ["\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  padding: 1.25em 0.625em;\n  user-select: none;\n\n  @media (min-width: ", "px) {\n    height: Max(18.75rem, 18.75em);\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoint.lg;
});
var PaddedImage = styled.img(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding: 10px;\n  width: 100%;\n"], ["\n  padding: 10px;\n  width: 100%;\n"])));
var CameraGlyph = styled.svg(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 6.25em;\n  margin-bottom: 15px;\n\n  g {\n    stroke: ", ";\n  }\n\n  ~ span {\n    font-size: 1.25em;\n    text-align: center;\n  }\n"], ["\n  width: 6.25em;\n  margin-bottom: 15px;\n\n  g {\n    stroke: ", ";\n  }\n\n  ~ span {\n    font-size: 1.25em;\n    text-align: center;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.primary;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
