var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ACTIVE_CUSTOM_THEME_KEY, isDefaultThemeActive, theme, } from "../../theme";
/**
 * Modifies SVG colors to match the application's theme colors. Needed because
 * the SVGs are static assets and not created with the theme in mind.
 *
 * This function solves the problem of maintaining consistent branding across SVG icons
 * by dynamically replacing specific colors with theme-based colors. It's particularly useful
 * when SVGs need to adapt to theme changes or custom branding requirements.
 *
 * The function:
 * 1. Fetches the SVG content from the provided URL
 * 2. Parses it as an XML document
 * 3. Finds all elements with 'fill' attributes
 * 4. Replaces specific color codes with theme colors:
 *    - #9CD3FF → theme.color.fillInfo
 *    - #203C63 → theme.color.text (or darkBlue100 as fallback)
 *
 * @param url - The URL of the SVG file to modify
 * @returns Promise<string> - A promise that resolves to the modified SVG content as a string
 */
export var modifySvgColors = function (url) { return __awaiter(void 0, void 0, void 0, function () {
    var response, svgText, parser, svgDoc, elementsWithFill, serializer, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                return [4 /*yield*/, fetch(url)];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.text()];
            case 2:
                svgText = _a.sent();
                parser = new DOMParser();
                svgDoc = parser.parseFromString(svgText, "image/svg+xml");
                elementsWithFill = svgDoc.querySelectorAll("[fill]");
                elementsWithFill.forEach(function (element) {
                    var currentFill = element.getAttribute("fill");
                    var isDefaultTheme = theme.color.primary.toUpperCase() === "#2BB7D9";
                    // Replace specific colors (add more as needed)
                    if (currentFill.toUpperCase() === "#9CD3FF" && !isDefaultTheme) {
                        element.setAttribute("fill", theme.color.primary);
                    }
                    else if (currentFill.toUpperCase() === "#203C63" && !isDefaultTheme) {
                        element.setAttribute("fill", theme.color.fillInfo);
                    }
                });
                serializer = new XMLSerializer();
                return [2 /*return*/, serializer.serializeToString(svgDoc)];
            case 3:
                error_1 = _a.sent();
                return [2 /*return*/, url];
            case 4: return [2 /*return*/];
        }
    });
}); };
/**
 * Retrieves the custom branding logo URL from localStorage, falling back to default if not found
 * @param defaultLogoUrl - The fallback logo URL to use if no custom logo is set
 * @returns The logo URL to display
 */
export var getCustomBrandingLogo = function (defaultLogoUrl) {
    try {
        // Check if we have any custom branding stored
        if (isDefaultThemeActive())
            return defaultLogoUrl;
        // Parse the stored theme JSON and extract the logo URL
        var parsedTheme = JSON.parse(localStorage.getItem(ACTIVE_CUSTOM_THEME_KEY));
        return (parsedTheme === null || parsedTheme === void 0 ? void 0 : parsedTheme.logo) || defaultLogoUrl;
    }
    catch (error) {
        // If anything fails (invalid JSON, localStorage unavailable, etc), use default
        return defaultLogoUrl;
    }
};
