var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { css, cx } from "@emotion/css";
import { useBlockComponentContext } from "../../form-blocks";
import { Box } from "../../box";
import { Icon } from "../../icon";
import { Text } from "../../text";
import { theme } from "../../theme";
export var GroupRow = function (_a) {
    var data = _a.data, expanded = _a.expanded, onClick = _a.onClick;
    var Translated = useBlockComponentContext().Translated;
    return (React.createElement("div", { onClick: function () { return onClick(data.id); }, id: "toggle-block-".concat(data.id, "-button") },
        React.createElement(Box, { flexDirection: "row", alignItems: "center", justifyContent: "space-between", className: cx("grouprow-0-43", cls.container) },
            React.createElement(Box, { flexDirection: "row", alignItems: "center" },
                React.createElement("div", { className: cx(cls.dropdownBtn, {
                        up: expanded,
                    }) },
                    React.createElement(Icon, { name: "sort-down", size: 14 })),
                React.createElement(Text, { className: cls.title, fontSize: "1.25em", lineHeight: "1.5em" },
                    React.createElement(Translated, { translationKey: data.title.translationKey }))),
            React.createElement(Status, { totalSlides: data.totalSlides, completedSlides: data.completedSlides, flaggedSlides: data.flaggedSlides }))));
};
var Status = function (_a) {
    var flaggedSlides = _a.flaggedSlides, completedSlides = _a.completedSlides, totalSlides = _a.totalSlides;
    if (flaggedSlides > 0) {
        return (React.createElement(Text, { color: "deepOrange100", fontSize: "1.25em" }, "Help requested"));
    }
    else {
        return (totalSlides - completedSlides === 0 && (React.createElement(Text, { color: "green100", fontSize: "1.25em" }, "Done")));
    }
};
var cls = {
    container: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    cursor: pointer;\n\n    &:hover * {\n      transform: translateY(-1px);\n      opacity: 0.7;\n    }\n  "], ["\n    cursor: pointer;\n\n    &:hover * {\n      transform: translateY(-1px);\n      opacity: 0.7;\n    }\n  "]))),
    dropdownBtn: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    position: relative;\n\n    display: grid;\n    place-items: center;\n\n    border: 1px solid;\n    border-color: ", ";\n    border-radius: 50%;\n\n    min-width: 1.75em;\n    min-height: 1.75em;\n    width: 1.75em;\n    height: 1.75em;\n\n    color: ", ";\n\n    box-shadow: 0 2px 10px 0 rgba(117, 121, 128, 0.2);\n\n    & > i {\n      margin-top: 0.25em;\n      font-size: 0.875em;\n    }\n\n    &.up {\n      border-color: ", ";\n      background-color: ", ";\n    }\n\n    &.up > i {\n      top: 5px;\n      left: 5px;\n      color: #fff;\n      transform: rotate(-90deg);\n    }\n  "], ["\n    position: relative;\n\n    display: grid;\n    place-items: center;\n\n    border: 1px solid;\n    border-color: ", ";\n    border-radius: 50%;\n\n    min-width: 1.75em;\n    min-height: 1.75em;\n    width: 1.75em;\n    height: 1.75em;\n\n    color: ", ";\n\n    box-shadow: 0 2px 10px 0 rgba(117, 121, 128, 0.2);\n\n    & > i {\n      margin-top: 0.25em;\n      font-size: 0.875em;\n    }\n\n    &.up {\n      border-color: ", ";\n      background-color: ", ";\n    }\n\n    &.up > i {\n      top: 5px;\n      left: 5px;\n      color: #fff;\n      transform: rotate(-90deg);\n    }\n  "])), theme.color.primary, theme.color.primary, theme.color.primary, theme.color.primary),
    title: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    font-weight: 600;\n    margin-left: 15px;\n    margin-top: 2px;\n  "], ["\n    font-weight: 600;\n    margin-left: 15px;\n    margin-top: 2px;\n  "]))),
};
var templateObject_1, templateObject_2, templateObject_3;
