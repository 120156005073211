/**
 * Key used to store the active custom branding theme in localStorage.
 */
export var ACTIVE_CUSTOM_THEME_KEY = "activeCustomBrandingTheme";
var DEFAULT_THEME_ID = "DEFAULT_THEME";
export var isDefaultThemeActive = function () {
    return localStorage.getItem(ACTIVE_CUSTOM_THEME_KEY) === DEFAULT_THEME_ID;
};
/** This function checks prevents the custom theme from being applied on dashboard. */
export var shouldApplyCustomStyle = function () {
    var isResidentExperience = window.location.pathname.includes("/admission/");
    return isResidentExperience && !isDefaultThemeActive();
};
