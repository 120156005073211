import {useQuery} from "react-query";

import {
  FeatureFlagsService
} from "../../../services/FeatureFlagsService";

export const useFetchAllFacilityFeatureFlags = () =>
  useQuery(
    "useFetchAllFacilityFeatureFlags",
    async () => {
      const {data} = await FeatureFlagsService.get_facility_feature_flags_types();
      return data;
    },
    // Always keep data in cache to avoid re-fetching this static data
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );
