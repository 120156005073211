import {styled} from "@reside/ui";

export const BrandingPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  @media (min-width: 1024px) {
    flex-direction: row;
    height: calc(100vh - 70px);
  }
`;

export const PreviewContainer = styled.div`
  flex: 1;
  background-color: ${({theme}) => theme.color.darkBlue10};
  height: calc(100vh - 70px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

export const ActionsHeader = styled.div`
  display: flex;
  width: 100%;
  padding: 0px 24px;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    margin-left: 16px;
  }
`;

export const CustomizeResidentExperienceContainer = styled.div`
  padding: 0 24px;
  min-height: 436px;
  overflow-y: auto;
  @media (min-width: 1024px) {
    height: 100%;
  }
`;

export const CustomizationBlocksWrapper = styled.div`
  display: flex;
  gap: 40px;
  overflow-x: auto;
  margin: 40px 0;
  padding-bottom: 20px;
  flex-wrap: wrap;

  #theme-name {
    order: 1;
    flex: 1 1 20%;
    min-width: 300px;
  }
  #upload-logo {
    order: 3;
    flex: 1 1 300px;
  }
  #colors {
    order: 2;
    flex: 1 1 300px;
  }

  @media (min-width: 1024px) {
    flex-direction: column;
    overflow-x: visible;

    #theme-name,
    #colors,
    #upload-logo {
      flex: 0 0 auto;
      width: 100%;
    }
    #theme-name {
      order: 1;
    }
    #colors {
      order: 3;
    }
    #upload-logo {
      order: 2;
    }
  }
`;

export const CustomizationBlockWrapper = styled.div`
  min-width: 300px;
  width: 100%;
  margin-bottom: 20px;
`;
