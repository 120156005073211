import {styled} from "@reside/ui";
import {ImageFit} from "./types";

export const ImagePreviewContainer = styled.div<{
  fit: ImageFit;
}>`
  width: 250px;
  height: 150px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: ${({fit}) => fit};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

/** Actually a lable of file input field that is styled to look like button. */

export const ImageUploadButton = styled.label`
  width: fit-content;
  padding: 8px 24px;
  border-radius: 4px;
  border: 1px solid ${({theme}) => theme.color.primary};

  background-color: ${({theme}) => theme.color.white};
  color: ${({theme}) => theme.color.primary};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);

  font-weight: ${({theme}) => theme.fontWeight.medium};
  font-size: 15px;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background-color: ${({theme}) => theme.color.primary};
    color: ${({theme}) => theme.color.white};
    transform: translateY(-1px);
  }
`;
