import {
  BrandingThemeColors,
  CustomThemeModel,
} from "../../services/BrandingService";
import {
  DEFAULT_RESIDE_COLOR_PALETTE,
  RED,
  ORANGE,
  YELLOW,
  GREEN,
  MINT,
  BLUE,
  PINK,
  PURPLE,
} from "./BRANDING_COLORS";

export const MAX_LOGO_SIZE_KB = 20;
export const MAX_THEMES = 4;
export const DEFAULT_THEME_INDEX = 0;
export const DEFAULT_LOGO_URL = "/assets/reside_logo.svg";
export const LOGO_SPECIFICATIONS = [
  `Maximum size: <strong>${MAX_LOGO_SIZE_KB} KB</strong>`,
  "Supported formats: <strong>SVG</strong>",
];

export const HIGH_CONTRAST_COLORS = [
  ORANGE.primary,
  YELLOW.primary,
  MINT.primary,
]; // Extend this list with more high contrast colors as needed.

/**
 * Key used to store the active custom branding theme in localStorage.
 */
export const ACTIVE_CUSTOM_THEME_KEY = "activeCustomBrandingTheme";

export const BRANDING_COLORS: BrandingThemeColors[] = [
  DEFAULT_RESIDE_COLOR_PALETTE,
  RED,
  ORANGE,
  YELLOW,
  GREEN,
  MINT,
  BLUE,
  PINK,
  PURPLE,
];

export const DEFAULT_THEME: CustomThemeModel = {
  id: "DEFAULT_THEME",
  organization_id: "",
  theme_name: "Reside Theme",
  logo: "",
  logo_filename: "",
  logo_type: "",
  logo_size: 0,
  colors: DEFAULT_RESIDE_COLOR_PALETTE,
  active: true,
  created: "",
  created_by: "system",
};
