import React from "react";
import {ImageUpload} from "../../../atoms/image-upload/ImageUpload";
import {FieldError, H3} from "@reside/ui";
import {Specifications} from "./Specifications";
import {CustomizationBlockWrapper} from "../styles";
import {DEFAULT_LOGO_URL, LOGO_SPECIFICATIONS} from "../constants";

export const UploadLogo = (): JSX.Element => {
  return (
    <CustomizationBlockWrapper id="upload-logo">
      <H3 style={{marginBottom: "16px"}}>Logo</H3>
      <div style={{paddingLeft: "10px"}}>
        <ImageUpload
          name="logo"
          uploadButtonText="Choose logo"
          defaultSrc={DEFAULT_LOGO_URL}
          acceptedMediaTypes={["image/svg+xml"]}
          fit="contain"
        />
        <Specifications specs={LOGO_SPECIFICATIONS} />{" "}
      </div>
      <FieldError name="logo" />
    </CustomizationBlockWrapper>
  );
};
