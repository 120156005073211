import React from "react";
import {Formik} from "formik";
import {useDeleteBrandingTheme} from "../hooks/useDeleteBrandingTheme";
import {
  H1,
  Button,
  Modal,
  ModalContent,
  ModalFooterButtons,
  Box,
} from "@reside/ui";

type Values = Readonly<{
  confirmed: boolean;
}>;

type Props = Readonly<{
  themeName: string;
  isOpen: boolean;
  onRequestClose: () => void;
  themeId: string;
}>;

export const DeleteThemeModal = ({
  themeName,
  isOpen,
  onRequestClose,
  themeId,
}: Props) => {
  const {mutate: deleteTheme, isLoading} = useDeleteBrandingTheme();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={isLoading ? undefined : onRequestClose}
    >
      {({closeModal}) => (
        <Formik<Values>
          initialValues={{
            confirmed: true,
          }}
          onSubmit={() => {
            deleteTheme(themeId);
            onRequestClose();
          }}
        >
          {({submitForm}) => (
            <>
              <ModalContent>
                <H1>Delete Theme: {themeName}</H1>
                <Box marginBottom={4}>
                  Are you sure you want to delete this theme? This action cannot
                  be undone.
                </Box>
              </ModalContent>
              <ModalFooterButtons>
                <Box
                  width="100%"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Button
                    outline
                    color="primary"
                    disabled={isLoading}
                    onClick={closeModal}
                    testId="closeDeleteThemeModalButton"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="danger"
                    disabled={isLoading}
                    onClick={submitForm}
                    testId="deleteThemeButton"
                  >
                    {isLoading ? "Deleting..." : "Delete"}
                  </Button>
                </Box>
              </ModalFooterButtons>
            </>
          )}
        </Formik>
      )}
    </Modal>
  );
};
