import React, {ReactNode} from "react";
import {Trans} from "@lingui/macro";
import {Button, styled, theme} from "@reside/ui";

import {IconButton} from "../../icon-button";
import {GoBackButton} from "../go-back-button";
import {Footer as BaseFooter} from "../../footer";
import {AdmissionContextConsumer} from "../AdmissionContext";

import {SlideShowProps} from "../slideshow";
import {useScrollContext} from "../scroll-context";
import {useFormikContext} from "formik";
import {isHighContrastColor} from "../../../utils/helpers/isHighContrastColor";

type Props = SlideShowProps;

export const NavbarBottom = ({
  isLastSlide,
  isAdmissionCompleted,
  isFirstSlide,
  isLogoutSlide,
  isSignSlide,
}: Props) => {
  const {scrolledToBottomBefore} = useScrollContext();
  const {isSubmitting} = useFormikContext();

  return (
    <AdmissionContextConsumer>
      {({
        draft,
        currentSlide,
        logoutSession,
        uiState: {textSize},
        uiActions: {pause, goToPreviousSlide, openSignModal},
      }) => (
        <NavbarBottomRenderer
          darkBackground={currentSlide.darkBackground}
          submitDisabled={
            isSubmitting ||
            (!isAdmissionCompleted && isLastSlide) ||
            (!currentSlide.state.complete &&
              !currentSlide.state.last &&
              !scrolledToBottomBefore)
          }
          submitTitle={
            <Trans id={currentSlide.ctaButton.title.translationKey} />
          }
          textSize={textSize}
          hideLeft={isFirstSlide}
          onPause={!draft.computed.readOnly ? pause : undefined}
          onLogout={isLogoutSlide ? logoutSession : undefined}
          onSign={isSignSlide ? openSignModal : undefined}
          onBack={goToPreviousSlide}
        />
      )}
    </AdmissionContextConsumer>
  );
};

type RendererProps = Readonly<{
  darkBackground: boolean;
  hideLeft: boolean;
  submitTitle: ReactNode;
  submitDisabled: boolean;
  textSize: number;
  onPause?: () => void;
  onBack: () => void;
  onSign?: () => void;
  onLogout?: () => void;
}>;

const NavbarBottomRenderer = ({
  darkBackground,
  hideLeft,
  submitTitle,
  submitDisabled,
  textSize,
  onPause,
  onBack,
  onSign,
  onLogout,
}: RendererProps) => (
  <Footer
    hasShadow={!darkBackground}
    textSize={textSize}
    left={
      hideLeft ? null : (
        <>
          <GoBackButton onClick={onBack} />
          {onPause && (
            <IconButton
              background="white"
              iconName="pause"
              testId="pause-button"
              onClick={onPause}
            >
              Pause
            </IconButton>
          )}
        </>
      )
    }
    right={
      /**
       * When continuing on last slide while admission is in progress
       * we open sign modal. Note the button cannot be of type submit
       * so we don't mark the slide as completed yet. The confirm button
       * in the SignModal should mark the last slide as complete.
       */
      onSign ? (
        <Button
          testId="continueButton"
          size="large"
          color="primary"
          disabled={submitDisabled}
          onClick={onSign}
        >
          {submitTitle}
        </Button>
      ) : onLogout ? (
        <Button
          testId="continueButton"
          size="large"
          color="primary"
          onClick={onLogout}
        >
          Logout
        </Button>
      ) : (
        <Button
          type="submit"
          testId="continueButton"
          size="large"
          disabled={submitDisabled}
          color={darkBackground ? "default" : "primary"}
        >
          {submitTitle}
        </Button>
      )
    }
  />
);

const Footer = styled(BaseFooter)<{textSize: number}>`
  --translate-x: ${({textSize}) => ((textSize - 1) / 3) * 75}px;
  --scale: ${({textSize}) => (textSize - 1) / 4 + 1};

  @media (min-width: 768px) {
    padding-block: 1rem;
    --normal: 1;
    --invert: -1;
  }

  button > i {
    color: ${({theme}) => {
      const primary = theme.color.primary;

      if (isHighContrastColor(primary)) {
        return theme.color.textPrimary;
      }

      return primary;
    }};
    border: 1px solid
      ${({theme}) => {
        const primary = theme.color.primary;

        if (isHighContrastColor(primary)) {
          return theme.color.textPrimary;
        }

        return primary;
      }};
  }

  [data-test-id="continueButton"] {
    outline: ${isHighContrastColor(theme.color.primary)
      ? `1px solid ${theme.color.textPrimary}`
      : "none"};
  }

  [data-test-id="go-back-button"] {
    padding-right: ${({textSize}) => textSize}rem;
  }
  [data-test-id="pause-button"],
  [data-test-id="go-back-button"] {
    transform: translateX(calc(var(--translate-x) * var(--invert, 1)));
    &:hover {
      transform: translate(
          calc(var(--translate-x) * var(--invert, 1)),
          -${({textSize}) => textSize}px
        )
        scale(var(--scale));
    }
  }
  .primary.large {
    transform: translateX(calc(var(--translate-x) * var(--normal, -1)))
      scale(var(--scale));
    &:hover {
      transform: translate(
          calc(var(--translate-x) * var(--normal, -1)),
          -${({textSize}) => textSize}px
        )
        scale(var(--scale));
    }
  }
  .primary.large,
  [data-test-id="pause-button"],
  [data-test-id="go-back-button"] {
    &:hover {
      transition-delay: 100ms;
      > i,
      > span {
        transition-delay: inherit;
      }
    }
  }
`;
