import React, {useState} from "react";
import {useFormikContext} from "formik";
import {Icon, Text} from "@reside/ui";

import {PreviewPageAdmissionPrelude} from "./PreviewPageAdmissionPrelude";
import {PreviewPageVerifyAnswers} from "./PreviewPageVerifyAnswers";
import {PreviewPageFirstSlide} from "./PreviewPageFirstSlide";
import {BRANDING_COLORS, DEFAULT_LOGO_URL} from "../../constants";
import {useObjectUrl} from "../../hooks/useObjectUrl";
import {PreviewBlockWrapper} from "./styles";

export const Preview = (): JSX.Element => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const {values} = useFormikContext<{
    logo: File | string | null;
    colors: string;
  }>();

  const logoPreviewUrl = useObjectUrl(values.logo, DEFAULT_LOGO_URL);

  const slides = [
    <PreviewPageVerifyAnswers
      key="verify"
      logoUrl={logoPreviewUrl}
      color={BRANDING_COLORS[+values.colors].primary}
    />,
    <PreviewPageAdmissionPrelude
      key="prelude"
      color={BRANDING_COLORS[+values.colors].primary}
      backgroundDefault={BRANDING_COLORS[+values.colors].backgroundDefault}
    />,
    <PreviewPageFirstSlide
      key="first"
      logoUrl={logoPreviewUrl}
      color={BRANDING_COLORS[+values.colors].primary}
      backgroundDefault={BRANDING_COLORS[+values.colors].backgroundDefault}
    />,
  ];

  const handlePrevious = () => {
    setCurrentSlide(prev => Math.max(0, prev - 1));
  };

  const handleNext = () => {
    setCurrentSlide(prev => Math.min(slides.length - 1, prev + 1));
  };

  return (
    <div>
      <div
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "auto",
        }}
        color={BRANDING_COLORS[+values.colors].primary}
      >
        {currentSlide > 0 && (
          <Icon
            name="left"
            onClick={handlePrevious}
            style={{flex: 0.5, cursor: "pointer"}}
          />
        )}

        <PreviewBlockWrapper>{slides[currentSlide]}</PreviewBlockWrapper>

        {currentSlide < slides.length - 1 && (
          <Icon
            name="right"
            onClick={handleNext}
            style={{flex: 0.5, textAlign: "right", cursor: "pointer"}}
          />
        )}
      </div>
      <Text as="p" fontSize={3} style={{textAlign: "center"}}>
        {currentSlide + 1}/{slides.length}
      </Text>
    </div>
  );
};
