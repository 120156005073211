import React, { useEffect, useState } from "react";
import { shouldApplyCustomStyle } from "../../theme/utils";
import { modifySvgColors, getCustomBrandingLogo } from "./utils";
var RESIDE_LOGO_IDENTIFIERS = ["reside-logo", "reside_logo"];
var DEFAULT_LOGO_STYLE = {
    maxWidth: "200px",
    maxHeight: "130px",
    marginBottom: "100px",
    width: "auto",
    height: "auto",
    objectFit: "contain",
    objectPosition: "center",
};
/**
 * BlockImage Component
 *
 * A component for rendering images within form blocks that supports:
 * - Dynamic SVG color modification based on theme (except for Reside logos)
 * - Custom branding for logo images in admission flows
 * - Responsive image styling with customizable alignment
 *
 * Special handling:
 * - Reside logos: Can be customized through branding theme in admission flows
 * - SVG files: Colors are automatically adjusted to match the application theme
 *
 * @component
 * @param {Object} props
 * @param {string} props.id - Unique identifier for the image
 * @param {string} props.url - Source URL of the image
 * @param {React.CSSProperties} props.style - Custom styles to apply to the image
 * @param {string} props.alt - Alternative text for accessibility
 * @param {string} props.horizontalAlignment - Horizontal alignment of the image
 */
export var BlockImage = function (_a) {
    var id = _a.id, url = _a.url, style = _a.style, alt = _a.alt, horizontalAlignment = _a.horizontalAlignment;
    var _b = useState(url), modifiedSvgUrl = _b[0], setModifiedSvgUrl = _b[1];
    var isResideLogo = RESIDE_LOGO_IDENTIFIERS.some(function (identifier) {
        return url.includes(identifier);
    });
    useEffect(function () {
        if (url.toLowerCase().endsWith(".svg") &&
            !RESIDE_LOGO_IDENTIFIERS.some(function (id) { return url.includes(id); })) {
            modifySvgColors(url).then(function (svgString) {
                // Convert the SVG string to a data URL
                var dataUrl = "data:image/svg+xml;base64,".concat(btoa(svgString));
                setModifiedSvgUrl(dataUrl);
            });
        }
    }, [url]);
    var imageUrl = isResideLogo && shouldApplyCustomStyle()
        ? getCustomBrandingLogo(modifiedSvgUrl)
        : modifiedSvgUrl;
    if (imageUrl) {
        var imageExtRegex = /\.(jpg|png)$/i;
        if (imageExtRegex.test(imageUrl)) {
            imageUrl = imageUrl.replace(imageExtRegex, ".webp");
        }
    }
    var imageStyle = isResideLogo && shouldApplyCustomStyle() ? DEFAULT_LOGO_STYLE : style;
    return (React.createElement("div", { style: { textAlign: horizontalAlignment } },
        React.createElement("img", { id: id, alt: alt, src: imageUrl, style: imageStyle, className: "blockimage-0-62" })));
};
