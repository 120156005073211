import {H1, H2} from "@reside/ui";
import React from "react";
import {Body, Center} from "../../styles";

export const FormBoxWrapper = ({children}: {children: React.ReactNode}) => {
  return (
    <div>
      <H1
        style={{textAlign: "center", marginTop: "30px"}}
        fontSize={[5, 6, 7]}
        lineHeight={["32px", "40px", "70px"]}
      >
        Welcome to the Reside Resident Experience
      </H1>

      <Body>
        <Center>
          <H2 style={{textAlign: "center", marginBottom: "30px"}}>
            Let’s get started...
          </H2>
          {children}
        </Center>
      </Body>
    </div>
  );
};
