import {useMutation} from "react-query";
import Alert from "react-s-alert";

import {
  FeatureFlagsService,
} from "../../../services/FeatureFlagsService";

export type UseUpdateFeatureFlagsParams = {
  id: string;
  featureTypes: ReadonlyArray<string>;
  onSettled: () => void;
  onSuccess: () => void;
};

export const useUpdateFacilityFeatureFlags = () =>
  useMutation(
    ({featureTypes, id}: UseUpdateFeatureFlagsParams) =>
      FeatureFlagsService.update_facility_feature_flags(featureTypes, id),
    {
      onError: async (error: any) => {
        try {
          const body = await error.json();

          Alert.error(body.message);
        } catch {
          Alert.error("Error updating feature flags");
        }
      },
      onSuccess: (_, {onSuccess}) => {
        onSuccess();
      },
      onSettled: (_, __, {onSettled}) => {
        onSettled();
      },
    },
  );
