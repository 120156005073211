import React, {ReactNode} from "react";
import cx from "classnames";

import {Spinner} from "./Spinner";

type Props = Readonly<{
  active?: boolean;
  children?: ReactNode;
  className?: string;
}>;

export const BackdropSpinner = ({active, className, children}: Props) => (
  <div className={cx("spinner-backdrop", className)}>
    {active && <Spinner backdrop />}
    {children}
  </div>
);
