import {styled} from "@reside/ui";
import {css} from "@emotion/react";

export const CheckableColor = styled.div<{checked: boolean}>`
  height: 66px;
  width: 66px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  outline: 2px solid
    ${({theme, checked}) => (checked ? theme.color.darkBlue100 : "transparent")};

  ${({checked, theme}) =>
    checked &&
    css`
      &:before {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        top: -16px;
        right: -11px;
        position: absolute;
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-7 -8 40 40'><path fill='none' stroke='white' stroke-width='3' d='M3 12l6 6L21 3'/></svg>");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        background-color: ${theme.color.darkBlue100};
        content: "";
        display: inline-block;
        visibility: visible;
      }
    `}
`;

export const ColorBox = styled.div<{bgColor: string}>`
  background-color: ${props => props.bgColor};
  width: 100%;
  height: 100%;
  border-radius: 8px;
`;
