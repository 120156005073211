import {styled} from "@reside/ui";
import {BrandingThemeColors} from "../../../../services/BrandingService";

const Color = styled.div<{
  color: string;
  position: "first" | "middle" | "last";
}>`
  width: 40px;
  height: 40px;
  background: ${({color}) => color};

  ${({position}) => {
    switch (position) {
      case "first":
        return `
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        `;
      case "last":
        return `
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        `;
      default:
        return "";
    }
  }}
`;

const PalettePreviewWrapper = styled.div`
  margin: 24px 0;
  display: flex;
  border: 1px solid ${({theme}) => theme.color.borderLightGray};
  border-radius: 8px;
  width: fit-content;
  justify-content: center;
`;

interface PalettePreviewProps {
  colors: BrandingThemeColors;
  align?: "center" | "left";
}

export const PalettePreview = ({
  colors,
  align = "center",
}: PalettePreviewProps) => {
  const {primary, fillSuccess, fillInfo} = colors;

  const showTwoColors = primary === colors.textInfo;

  return (
    <PalettePreviewWrapper
      style={{
        margin: align === "center" ? "32px auto 8px auto" : "8px",
      }}
    >
      <Color color={primary} position="first" />
      <Color color={fillSuccess} position={showTwoColors ? "last" : "middle"} />
      {!showTwoColors && <Color color={fillInfo} position="last" />}
    </PalettePreviewWrapper>
  );
};
